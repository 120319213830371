import React, { useState } from 'react';
import Header from './header';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import toastr from 'reactjs-toastr';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';
import { IoCloseSharp } from 'react-icons/io5';
import ReCAPTCHA from 'react-google-recaptcha';

const Register = () => {
  const history = useHistory();
  const location = useLocation();
  const code = location?.search?.split('=')[1];

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const closeDialog = () => setIsDialogOpen(false);
  const openDialog = () => setIsDialogOpen(true);

  const initialValues = {
    full_name: '',
    email: '',
    password: '',
    confirm_password: '',
    referral_code: code || '',
  };

  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required('Full Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
    confirm_password: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  const headers = {
    'Content-Type': 'text/plain',
  };

  const onSubmit = async (values) => {
    setIsLoading(true);

    if (!recaptchaToken) {
      toastr.error('Please complete the CAPTCHA', { displayDuration: 3000 });
      setIsLoading(false);
      return;
    }

    try {
      const data = { ...values};
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/users/register`,
        data,
        { headers }
      );

      if (response?.data?.success) {
        toastr.success(response.data.message, { displayDuration: 3000 });
        openDialog();
      } else {
        toastr.error(response.data.message || 'Registration failed', { displayDuration: 3000 });
      }
    } catch (error) {
      console.error('Registration Error:', error);
      toastr.error('An error occurred. Please try again.', { displayDuration: 3000 });
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <>
      <div style={{ height: '100%', overflow: 'hidden', backgroundColor: '#323132' }}>
        <div style={{ backgroundColor: '#0E0E10', display: 'flex', justifyContent: 'center', minHeight: '100vh', alignItems: 'center', flexDirection: 'column' }}>
          <form style={{ maxWidth: '600px', backgroundColor: '#18181B', color: '#fff', padding: '30px', width: '100%' }} onSubmit={formik.handleSubmit}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Link to="/">
                <img className="img-responsive" alt="Nothing" src="/victus.png" style={{ width: '70px', height: '70px', borderRadius: '100%' }} />
              </Link>
              <h3 className="purple oR m0">Join Victus today</h3>
            </div>

            <div className="modal-body">
              <div className="form-container">
                <strong>Full Name</strong>
                <input
                  placeholder="Full Name"
                  onBlur={formik.handleBlur}
                  value={formik.values.full_name}
                  onChange={formik.handleChange}
                  name="full_name"
                  type="text"
                  className="input-field"
                />
              </div>
              <div className="errorMessage_signup">{formik.errors.full_name}</div>

              <div className="form-container">
                <strong>Email</strong>
                <input
                  placeholder="Email Address"
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  name="email"
                  type="email"
                  className="input-field"
                />
              </div>
              <div className="errorMessage_signup">{formik.errors.email}</div>

              <div className="form-container">
                <strong>Login Key</strong>
                <input
                  placeholder="Login Key"
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  name="password"
                  type="password"
                  className="input-field"
                />
              </div>
              <div className="errorMessage_signup">{formik.errors.password}</div>

              <div className="form-container">
                <strong>Confirm Login Key</strong>
                <input
                  placeholder="Confirm Login Key"
                  onBlur={formik.handleBlur}
                  value={formik.values.confirm_password}
                  onChange={formik.handleChange}
                  name="confirm_password"
                  type="password"
                  className="input-field"
                />
              </div>
              <div className="errorMessage_signup">{formik.errors.confirm_password}</div>

              <div className="form-container">
                <strong>Referral Code</strong>
                <input
                  placeholder="Referral Code"
                  value={formik.values.referral_code}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="referral_code"
                  type="text"
                  className="input-field"
                />
              </div>

              <div className="form-container" style={{ marginTop: '20px' }}>
                <ReCAPTCHA
                  sitekey="6Lcx49kqAAAAABflcOLTeJA88gcOKf99K0LhHF6R" // Replace with your reCAPTCHA Site Key
                  onChange={(token) => setRecaptchaToken(token)} // Save the token when user completes CAPTCHA
                  onExpired={() => setRecaptchaToken(null)} // Reset the token if CAPTCHA expires
                />
              </div>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn btn-gradient" style={{ width: '100%' }}>
                {isLoading ? 'Registering...' : 'Register'}
              </button>
            </div>

            <p style={{ textAlign: 'center', fontSize: '20px', color: '#fff' }}>
              Have an account?{' '}
              <Link to="/login" style={{ color: '#AE8A3D' }}>
                Login
              </Link>
            </p>
          </form>
        </div>
      </div>
    </>
  );
};

export default Register;
