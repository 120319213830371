import React, { useEffect, useState } from 'react'
import { IoCloseSharp } from "react-icons/io5";
import { FiPlus } from "react-icons/fi";
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import axios from 'axios';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { RxVideo } from "react-icons/rx";
import { AiOutlineLike } from "react-icons/ai";
import { MdModeComment } from "react-icons/md";
import { PiShareFatFill } from "react-icons/pi";

const StoryPage = () => {
  const [cookieData, setCookieData] = useState({});
  const[storyData,setStoryData] = useState()
  const[storyTimeline,setStoryTimeline] = useState()
  const location = useLocation()
  // console.log(location,'loctiom')
  const userId = location?.search?.split('=')[1];
  // console.log(userId,'code')
 
  // console.log(cookieData,'cookiedata')
  

   
      const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
      const [progress, setProgress] = useState(0);

      useEffect(() => {
        const interval = setInterval(() => {
          if (progress < 100) {
            setProgress(progress + 1);
          } else {
            if (currentStoryIndex < storyData?.length - 1) {
              setCurrentStoryIndex(currentStoryIndex + 1);
              setProgress(0); // Reset progress for next story
            } else {
              clearInterval(interval); // Stop interval when all stories are shown
            }
          }
        }, 100); // Increment progress every 100 milliseconds
    
        return () => clearInterval(interval); // Clean up interval on unmount
      }, [progress, currentStoryIndex]);
    
      const handleNext = () => {
        if (currentStoryIndex < storyData?.length - 1) {
          setCurrentStoryIndex(currentStoryIndex + 1);
          setProgress(0); // Reset progress when manually changing stories
        }
      };
    
      const handlePrev = () => {
        if (currentStoryIndex > 0) {
          setCurrentStoryIndex(currentStoryIndex - 1);
          setProgress(0); // Reset progress when manually changing stories
        }
      };

      let prgLength = 90/storyData?.length
      

      const getUserStoryData = async(id) => {

      if(id){
        window.location.hash = `/story/?storyid=${id}`;
      }
        
        if(Object.keys(cookieData).length > 0 ){
      
          let data = {
            'api_key':cookieData?.api_key,
            'user_id':cookieData?.id,
            'follow_user_id':id ? id : userId
          }

        
        try {
          let response = await axios.post(`${process.env.REACT_APP_URL}/api/users/get_user_story`,
          data,
          {
            headers:{
              'Content-Type': 'text/plain',
              'Accept':'application/json, text/plain, */*'
            }
           });
          if(response.data.code === true){
            setStoryData(response?.data?.story_data)
          }
        } catch (error) {
          console.log(error)
        }
      }
       
      }


      const getStoryTimeline = async() => {

       
        
        if(Object.keys(cookieData).length > 0 ){
            
          let data = {
            'api_key':cookieData?.api_key,
            'user_id':cookieData?.id
          }
        try {
          let response = await axios.post(`${process.env.REACT_APP_URL}/api/users/story_timeline`,
          data,
          {
            headers:{
              'Content-Type': 'text/plain',
              'Accept':'application/json, text/plain, */*'
            }
           });
          if(response){
            setStoryTimeline(response?.data?.recdata)
            
            return true
          }
        } catch (error) {
          console.log(error)
        }
      }
       
      }

      useEffect(() => {

        let data = Cookies.getJSON('name');
        setCookieData(data?.user_data)
        if(Object.keys(cookieData).length > 0 ){
          
          getStoryTimeline()
        }
        
      }, []);

      // console.log(storyData,'storyData')
      // console.log(storyTimeline,'timeline')

      useEffect(()=>{
        getUserStoryData()
        getStoryTimeline()
      },[cookieData])

      const filteredMyStory = storyTimeline?.filter(item => cookieData?.id.includes(item?.user_id));

     
        const filteredUserStory = storyTimeline?.filter(item => item?.user_id !== cookieData?.id);
        // console.log(filteredUserStory,'story')
 


  return (
    <>
      <div style={{width:'100%',display:'flex',justifyContent:'space-between',background:'black'}}>
        <div style={{width:'22%',background:'black',height:'100vh'}}>
            <div style={{height:'70px',borderBottom:'1px solid rgba(0,0,0,0.4)',width:'100%',display:'flex',gap:'0px',alignItems:'center'}}>
                <Link to="/dashboard">
                <div style={{background:'rgba(0,0,0,0.2)',borderRadius:'50px',marginLeft:'20px',display:'flex',alignItems:'center'}}><IoCloseSharp style={{fontSize:'40px',color:'white'}}/></div>
                </Link>
            <div >
          <img src='/victus.png' style={{height:'60px'}}/>
         </div>

         <div style={{height:'40px',display:'flex',background:'white',alignItems:'center',justifyContent:'center',gap:'5px',padding:'0px 10px',borderRadius:'15px',marginLeft:'10px'}}>
          <RxVideo style={{fontSize:'22px'}}/>
            <div style={{fontWeight:'500'}}>Create Reel</div>
         </div>
            </div>


            {/* <div style={{padding:'20px'}}>
                <h3 style={{color:'black'}}>Stories</h3>
            </div> */}

            {/* <div style={{padding:'0px 20px'}}>
                <h5>Your Story</h5>
                
                {filteredMyStory?.length > 0 ? (
                   
                <div onClick={()=>getUserStoryData(filteredMyStory[0]?.user_id)} className={filteredMyStory[0]?.user_id === userId ? 'bgGray':'bgLight'} style={{display:'flex',alignItems:'center',gap:'15px',height:'70px',padding:'0px 10px',borderRadius:'10px',cursor:'pointer'}}>
                <div style={{height:'50px',width:'50px',border:'5px solid #D8B471',borderRadius:'50px',left:'10px',top:'10px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                          <img src={filteredMyStory[0]?.profile_pic} style={{height:'40px',width:'43px',borderRadius:'50px'}}/>
                                      </div>
                                      <div>
                                          <div style={{color:'black'}}>{filteredMyStory[0]?.full_name}</div>
                                          <div style={{fontSize:'13px'}}>3hrs</div>
                                      </div>
                </div>
                ):(<div style={{display:'flex',gap:'10px',margin:'20px 0px',alignItems:'center'}}>
                <div style={{height:'50px',width:'50px',background:'rgba(0,0,0,0.1)',borderRadius:'50px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                     <FiPlus color='blue'/>
                </div>
                <div>
                <div style={{color:'black'}}>Create your story</div>
                  <div style={{fontSize:'12px'}}>Add photos/videos</div>
                </div>
             </div>)}
            </div> */}

            {/* <div style={{padding:'0px 20px'}}>
                <h5>All Stories</h5>
                   <div style={{display:'flex',flexDirection:'column',gap:'10px'}}>
                {filteredUserStory && filteredUserStory.map((item,index)=>(

                <div onClick={()=>getUserStoryData(item?.user_id)}  key={index} className={item?.user_id === userId ? 'bgGray':'bgLight'} style={{display:'flex',alignItems:'center',gap:'15px',height:'70px',padding:'0px 10px',borderRadius:'10px',cursor:'pointer'}}>
                <div style={{height:'50px',width:'50px',border:'5px solid #D8B471',borderRadius:'50px',left:'10px',top:'10px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                          <img src={item?.profile_pic} style={{height:'40px',width:'43px',borderRadius:'50px'}}/>
                                      </div>
                                      <div>
                                          <div style={{color:'black'}}>{item?.full_name}</div>
                                          <div style={{fontSize:'13px'}}>3hrs</div>
                                      </div>
                </div>
                ))}
                   </div>
            </div> */}
        </div>
        <div style={{width:'70%',height:'100vh',background:'black',display:'flex',flexDirection:'column',justifyContent:'center',gap:'10px'}}>
                <div style={{width:'580px',height:'90vh',borderRadius:'15px',display:'flex',justifyContent:'center',alignItems:'center',gap:'20px'}}>
                <div onClick={handlePrev} style={{background:'white',borderRadius:'50px',marginLeft:'20px',display:'flex',alignItems:'center',padding:'10px',cursor:'pointer'}}><IoIosArrowBack style={{fontSize:'30px'}}/></div>
                <div className="story" style={{width:'380px',height:'90vh',borderRadius:'15px',position:'relative'}}>
                
        <img src={storyData && storyData[currentStoryIndex]?.file} alt="Story" style={{width:'380px',height:'90vh',borderRadius:'15px'}}/>

        {/* <div style={{display:'flex',flexWrap:'wrap',position:'absolute',top:'10px',width:'100%',left:'10px',gap:'5px'}}>
        {storyData && storyData.map((item,index)=>(
            <>
        <div key={index} style={{width: `${prgLength}%`,display:'flex',height:'5px',borderRadius:'3px',background:'rgba(0,0,0,0.3)'}}>
        <div className="progress-bar" style={{ width: `${progress}%`,background:'white',height:'5px',borderRadius:'3px' }} />
        </div>
            </>
        ))}
        </div> */}

        <div style={{display:'flex',alignItems:'center',gap:'15px',height:'70px',padding:'0px 10px',borderRadius:'10px',position:'absolute',top:'5px'}}>
                <div style={{height:'50px',width:'50px',border:'5px solid #D8B471',borderRadius:'50px',left:'10px',top:'10px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                          <img src='https://img.freepik.com/free-photo/young-beautiful-woman-pink-warm-sweater-natural-look-smiling-portrait-isolated-long-hair_285396-896.jpg?t=st=1712910396~exp=1712913996~hmac=87fc987f7809e106e86cfb13ffec013049e3add7e0387d7ecf637021789a4383&w=996' style={{height:'40px',width:'43px',borderRadius:'50px'}}/>
                                      </div>
                                      <div>
                                          <div style={{color:'white'}}>{storyData && storyData[currentStoryIndex]?.full_name}</div>
                                          <div style={{fontSize:'14px',color:'white'}}>{storyData && storyData[currentStoryIndex]?.duration}</div>
                                      </div>
                </div>

                <div style={{position:'absolute',display:'flex',flexDirection:'column',gap:'40px',alignItems:'center',bottom:'0px',right:'-70px'}}>
                   <AiOutlineLike style={{fontSize:'30px',color:'white'}}/>
                   <MdModeComment style={{fontSize:'30px',color:'white'}}/>
                   <PiShareFatFill style={{fontSize:'30px',color:'white'}}/>
                </div>



       
      </div>

      <div onClick={handleNext} style={{background:'white',borderRadius:'50px',marginLeft:'20px',display:'flex',alignItems:'center',padding:'10px',cursor:'pointer'}}><IoIosArrowForward style={{fontSize:'30px'}}/></div>
                </div>

                {/* <div style={{width:'30%'}}>
                  <input type='text' placeholder='Reply' style={{width:'100%',border:'1px solid white',borderRadius:'15px',height:'45px',padding:'0px 10px'}}/>
                </div> */}
        </div>
      </div>
    </>
  )
}

export default StoryPage
