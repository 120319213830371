import React, { Component, useState } from 'react';
import Cookies from 'js-cookie';
import { Link,useLocation,withRouter } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import toastr from 'reactjs-toastr';
import { Notifications } from 'react-push-notification';
import addNotification from 'react-push-notification';
import InnerSidebar1 from './innerSidebar1';
import { useEffect } from 'react';
import { MdGroups } from "react-icons/md";
import { SiCoinmarketcap } from "react-icons/si";
import { SiCrowdsource } from "react-icons/si";
import { GiVote } from "react-icons/gi";
import { BsCameraReelsFill, BsThreeDots } from "react-icons/bs";
import { BiSolidHome } from "react-icons/bi";

import inner from './inner.css'
import { Avatar, Drawer, InputAdornment, TextField } from '@material-ui/core';
import Sidebar from './Sidebar';
import { RiMessengerFill } from 'react-icons/ri';
import { IoClose, IoSearch } from 'react-icons/io5';
import { FaArrowLeftLong } from "react-icons/fa6";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


const headers = {
    'Content-Type': 'text/plain'
 };
 class InnerHeader extends Component {

    

    constructor(props) {
        super(props)
        console.log(this.props.location)
        let data = Cookies.getJSON('name');
        this.loginData = data?.user_data
        this.main_search = this.main_search.bind(this);
        this.main_search_val = this.main_search_val.bind(this);
        this.notificationRead = this.notificationRead.bind(this)
        this.notificationReadBell = this.notificationReadBell.bind(this);
        this.notificationReadSingle = this.notificationReadSingle.bind(this)
        
        this.state = {
            main_search_val: '',
            searchData: [],
            NotifData: [],
            open:this.props.open,
            opensidebar:false ,
            users_list: [],
            openSeachDialog:false
        }
    }
    

    main_search_val(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
        axios.post(`${process.env.REACT_APP_URL}/api/users/all_search`, { 'user_id': this.loginData.id, 'search': this.state.main_search_val, 'api_key': this.loginData.api_key },{headers}).then((res) => {
            this.codeDataAvatarBanner1 = res.data.code
            if (this.codeDataAvatarBanner1 === true) {
                this.setState({
                    searchData: res.data.recdata
                });
                $('.search_ul').show();
            } else {
                this.setState({
                    searchData: ['']
                });
                $('.search_ul').hide();
            }

        }).catch((error) => {
        });
    }

    componentDidMount() {
        $('#particles-js').hide();
        this.BannerImageAPI()
        this.user_search1()
        
        setInterval(() => {
            this.user_search1()
            this.notificationList()
        }, 10000);

        $("body").delegate("#search-friends", "click", function (e) {
            e.stopPropagation();
        });

        $("body").click(function () {
            $('.searched').removeClass("active");
            $('.user-setting').removeClass("active");
        });
        $('body').delegate('.top-area > .setting-area > li > .menu-a', "click", function () {
            var $parent = $(this).parent('li');
            $parent.siblings().children('div').removeClass('active');
            $(this).siblings('div').addClass('active');
        });
        $('body').delegate('.top-area > .setting-area > li > .menu-search', "click", function () {
            var $parent = $(this).parent('li');
            $parent.siblings().children('div').removeClass('active');
            $(this).siblings('div').addClass('active');
            return false;
        });
        $('.user-img img').click(function (e) {
            $('.user-setting').toggleClass("active");
            setTimeout(function(){
            $('.searched').removeClass('active');
            });
            e.stopPropagation();
         });
    }

    async  main_search(e) {
        e.preventDefault()
        if (this.state.main_search_val.length === 0) {
            this.setState({
                searchData: ['']
            });
            $('.search_ul').hide();
            return false;
        }
        axios.post(`${process.env.REACT_APP_URL}/api/users/all_search`, { 'user_id': this.loginData.id, 'search': this.state.main_search_val, 'api_key': this.loginData.api_key },{headers}).then((res) => {
            this.codeDataAvatarBanner1 = res.data.code
            if (this.codeDataAvatarBanner1 === true) {
                this.setState({
                    searchData: res.data.recdata
                });
                $('.search_ul').show();
            } else {
                this.setState({
                    searchData: ['']
                });
                $('.search_ul').hide();
            }
        }).catch((error) => {
        });

    }
    //==================================  Detail of Avatar Banner  ==============================

    BannerImageAPI() {
        axios.post(`${process.env.REACT_APP_URL}/api/users/avatar_banner`, { 'user_id': this.loginData.id, 'view_user_id': this.loginData.id, 'api_key': this.loginData.api_key },{headers}).then((res) => {
            this.codeDataAvatarBanner = res.data.code
            if (this.codeDataAvatarBanner === true) {
                this.setState({
                    bannerImage: res.data.recdata
                });
            }
            else {
                toastr.error("This account is open at somewhere, So it's logout", { displayDuration: 3000 })
                setTimeout(() => {
                    Cookies.remove('name');

                    window.location.hash = '/';
                }, 200);
            }
        }).catch((error) => {
        });

    }
    //================   Logout  ==============================

    Logout() {
        Cookies.remove('name');
        setTimeout(() => {

            window.location.hash = '/login';
            window.location.reload(true)

        }, 200);
    }

    loading(id) {
        setTimeout(() => {
            window.location.hash = '/timeLine/' + id;
            window.location.reload(true)
        }, 500);
    }

    loadingSetting(id) {
        setTimeout(() => {
            window.location.hash = '/setting'
            window.location.reload(true)
        }, 500);
    }

    loadingGroup(id) {
        setTimeout(() => {
            window.location.hash = '/groupdetail/' + id;
            window.location.reload(true)
        }, 500);
    }

    loginPage() {
        setTimeout(() => {
            window.location.reload(true)
        }, 200);
    }

    //==================================  Notifications list  ==============================

    notificationList() {
        axios.post(`${process.env.REACT_APP_URL}/api/users/notification_list`, { 'user_id': this.loginData.id, 'api_key': this.loginData.api_key, 'limit': 10 },{headers}).then((res) => {
            this.codeDataNotification = res.data.code
            if (this.codeDataNotification === true) {
                this.notifCount = res.data
                setTimeout(() => {

                    $.each(res.data.recdata, function () {
                        if (this.is_flash === '0') {
                            addNotification({
                                title: this.title,
                                subtitle: 'This is a subtitle',
                                // message: this.message,
                                theme: 'darkblue',
                                native: true // when using native, your OS will handle theming.

                            })
                        } else {
                        }

                    })
                }, 1000);

                this.setState({
                    NotifData: res.data.recdata
                });

            }
            else {
                this.setState({
                    NotifData: []
                })
            }
        }).catch((error) => {
        });

    }

    pushnotification(item) {
        addNotification({
            title: item.title,
            subtitle: 'This is a subtitle',
            message: item.message,
            theme: 'darkblue',
            duration: 3000,
            native: true // when using native, your OS will handle theming.

        })
    }

    user_search1(id) {
    
        let val = $('#user_search').val();
      
        
        axios.post(`${process.env.REACT_APP_URL}/api/users/search_user`, { 'user_id': this.loginData?.id, 'api_key': this.loginData?.api_key, 'name': val },{headers}).then((res) => {
         
  
           if (res.data.code === true) {
              this.setState({
                 users_list: res.data.users,
                 message: ''
              });
             
           }
           else {
              this.setState({
                 message: res.data.message
              });
  
           }
  
         
  
        }).catch((error) => {
         
        });
     }


     select_user(item) {
        axios.post(`${process.env.REACT_APP_URL}/api/users/start_chat`, { 'user_id': this.loginData.id, 'friend_id': item.id, 'api_key': this.loginData.api_key },{headers}).then((res) => {
           //on success
           if (res.data.code === true) {
              this.current_chating();
              setTimeout(() => {
                 $('#scroll_chat_box' + item.id).scrollTop($('#scroll_chat_box' + item.id)[0]?.scrollHeight);
              }, 1000);
           }
  
        }).catch((error) => {
           //on error
        });
  
     }

     
   closeChatDialog= () =>{
    console.log('close')
    $("#message_user_list").hide()
 }

 openChatDialog= () =>{
    console.log('close')
    $("#message_user_list").show()
 }

    //===================================== Notification Read  ===========================

    notificationRead() {
        axios.post(`${process.env.REACT_APP_URL}/api/users/notification_read`, { 'user_id': this.loginData.id, 'api_key': this.loginData.api_key, 'notification_id': "0" },{headers}).then((res) => {
            this.codeDataNotificationReadAll = res.data.code
            if (this.codeDataNotificationReadAll === true) {
                window.location.hash = '/AllNotification';
                window.location.reload(true)

            }
            else {

            }
        }).catch((error) => {
        });
    }

    //===================================== Notification Bell click Read  ===========================

    notificationReadBell() {
        axios.post(`${process.env.REACT_APP_URL}/api/users/notification_read`, { 'user_id': this.loginData.id, 'api_key': this.loginData.api_key, 'notification_id': "0" },{headers}).then((res) => {
            this.codeDataNotificationReadAll = res.data.code
            if (this.codeDataNotificationReadAll === true) {

            }
            else {

            }
        }).catch((error) => {
        });
    }

    //===================================== Notification read single  ====================

    notificationReadSingle(id) {
        console.log(id)
        axios.post(`${process.env.REACT_APP_URL}/api/users/notification_read`, { 'user_id': this.loginData.id, 'api_key': this.loginData.api_key, 'notification_id': id.notification_id },{headers}).then((res) => {
            this.codeDataNotificationReadAll = res.data.code
            if (this.codeDataNotificationReadAll === true) {
                window.location.hash = id.link
                setTimeout(() => {
                    window.location.reload(true)
                }, 1000);

            }
            else {

            }
        }).catch((error) => {
        });
    }

    loading1(id) {
        console.log(id.link);
        
        window.location.hash = id.link
        setTimeout(() => {
            window.location.reload(true)
        }, 1000);
    }

    handleOpen = ()=>{
        // this.setState({open:!this.state.open})
        this.setState({
            opensidebar:true
        })
    }

    handleNavigation = (id) => {
        // Use this.props.history to navigate
        this.props.history.push(`timeline/${id}`);
      };
    
    
    render() {
      

        return (
            <div className="topbar stick stickClass" style={{position:'relative'}}>

                <div className='middlebar'>

               
                <span className="logo text-right innerLogo" style={{display:'flex',alignItems:'center',gap:'10px'}}>
                    <Link title="" to="/" onClick={this.loginPage}><img className='logosize' src="/victus.png" alt="" /></Link>

                <div className="search-container">
                    <IoSearch className="search-icon"/>
                   <input onClick={()=>this.setState({openSeachDialog:true})} type="text" className="search-field" placeholder="Search..."/>
              
                </div>

                <div className='search-container2'>
                <IoSearch onClick={()=>this.setState({openSeachDialog:true})} className="search-icon" style={{color:'black'}}/>
                </div>

{this.state.openSeachDialog && 
                   <div className='seachDialog' style={{height:'600px',position:'absolute',left:'0px',top:'0px',background:'white',overflowY:'scroll',padding:'10px 20px',borderRadius:'10px',boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'}}>
                      <div style={{display:'flex',alignItems:'center',gap:'10px'}}>

                         <FaArrowLeftLong onClick={()=>this.setState({openSeachDialog:false})} style={{fontSize:'25px',cursor:'pointer'}}/>
                      <div className="search-container3">
                    <IoSearch className="search-icon"/>
                   <input  type="text" className="search-field" placeholder="Search..." id="user_search" 
                   onChange={this.user_search1.bind(this, 1)}/>
              
                </div>
                      </div>

                             {(this.state.users_list.length > 0) ?
                        this.state.users_list.map(item => (
                           <div className="m-messenger--userlist-conversations-item " onClick={()=>this.handleNavigation(item?.id)}>
                              <div style={{display:'flex',gap:'5px',alignItems:'center'}}>
                              <img className="mdl-shadow--2dp" src={item.avatar} />
                           <span className="m-conversation-label">{item.full_name}</span>
                              </div>

                              <div className='dot' style={{display:'flex',alignItems:'center'}}>
                              <div className='hiddendots' style={{border:'0.001px solid rgba(0,0,0,0.2)',borderRadius:'50px',display:'flex',alignItems:'center',background:'white'}}>
                               <BsThreeDots style={{fontSize:'20px',margin:'5px'}}/>
                              </div>
                              <span className="m-conversation-icons"><span className="m-conversation-new mdl-color--green" hidden=""></span>
                           <span className="m-conversation-online mdl-color--blue"></span></span>
                              </div>
                           </div>
                        )) :
                        <p className="center">Search user</p>
                     }
                   </div>
}
                </span>

                <Notifications />


                    {this.state.open &&  <div  style={{position:"absolute",right:"0px",top:"0px",bottom:"0px",padding:"20px",backgroundColor:"#fff",maxHeight:"100vh",width:"100vw",overflow:"scroll",minHeight:"100vh",zIndex:999999 }}>
                   
                      

                        <div className='mt-2' style={{zIndex:9,position:'absolute'}}>
                        <div className=''style={{height:'40px',background:'black',position:'relative',top:0,zIndex:"99999"}} >
                        <span aria-hidden="true" style={{fontSize:"24px",textAlign:"end",color:'white'}} onClick={this.handleOpen}>&times;</span>
                        </div>
                            <InnerSidebar1/>
                        </div>
                   
                    </div>}

                   


<div className="top-area">




                    <ul className="setting-area"  style={{display:"flex",listStyle:"none",alignItems:"center"}}>
                      

                       
                       
                        <li >
                            <a href="javascript:;" title="Notification" onClick={this.notificationReadBell} data-ripple="" className="head-menu menu-a" style={{background:'#D8DADF',borderRadius:'50%',height:'40px',width:'40px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <i className="fa fa-bell-o text-black  " aria-hidden="true" style={{fontSize:'20px'}}></i><span className="badge" >{this.notifCount?.unread_count}</span>
                            </a>
                            <div className="dropdowns ps-container ps-theme-default ps-active-y notif" >
                                <span style={{fontSize:'22px',fontWeight:'600',color:'black'}}>Notifications</span>
                                <ul className="drops-menu">
                                    {this.state.NotifData.map(item => (
                                        item.isread === '0' ? <li onClick={this.notificationReadSingle.bind(this, item)} style={{ background: 'whitesmoke' }}>
                                            <Link to="/" title="">
                                                <img src={item.profile_pic} alt="icon/demo-avatar.jpg" />
                                                <div className="mesg-meta">
                                                    <h6>{item.sender}</h6>
                                                    <span title={item.message}>{item.title}</span>
                                                    <i>{item.duration}</i>
                                                </div>
                                            </Link>
                                          
                                        </li> :
                                            <li onClick={this.loading1.bind(this, item)} >
                                                <Link to="/" title="">
                                                    <img src={item.profile_pic} alt='icon/demo-avatar.jpg' />
                                                    <div className="mesg-meta">
                                                        <h6>{item.sender}</h6>
                                                        <span title={item.message}>{item.title}</span>
                                                        <i>{item.duration}</i>
                                                    </div>
                                                </Link>
                                                {/* <span className="tag green">New</span> */}
                                            </li>
                                    ))}
                                </ul>
                                <Link to="/AllNotification" onClick={this.notificationRead} title="" className="more-mesg">view more</Link>
                            </div>
                            {/* </span> */}
                        </li>
                      

                        

                      <div onClick={this.openChatDialog} style={{background:'#D8DADF',borderRadius:'50%',height:'40px',width:'40px',display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer',position:'relative'}}>
                          <RiMessengerFill style={{fontSize:'25px',color:'black'}} />
                        </div>  

                          <div  id="message_user_list" className='messengerPopup'  style={{ display: "none",position:'absolute',top:'85px',right:'0px',width:'360px',zIndex:'100',boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',borderRadius:'20px',scrollbarWidth:'thin' }}>
                  <div minds-messenger-scroll="" className="m-messenger--userlist-conversations" >
                     <div style={{display:'flex',justifyContent:'space-between',width:'100%'}}>
                        <p style={{fontSize:'26px',fontWeight:'500',color:'black'}}>Chats</p>

                        <div>
                        <IoClose style={{fontSize:'25px',cursor:'pointer'}} onClick={this.closeChatDialog}/>
                        </div>
                     </div>
                  <div className="m-messenger--userlist-search " style={{height:'45px',marginBottom:'20px',position:'relative'}}>
                     <i className="material-icons fa fa-search icons-set" style={{top:'20px'}}></i>
                     <input type="text" placeholder="Search Messenger..." id="user_search" onChange={this.user_search1.bind(this, 1)} style={{padding:'0px 30px',margin:'0px'}}/>
                     </div>
                     {(this.state.users_list.length > 0) ?
                        this.state.users_list.map(item => (
                           <div className="m-messenger--userlist-conversations-item " onClick={this.select_user.bind(this, item)}>
                              <div style={{display:'flex',gap:'5px',alignItems:'center'}}>
                              <img className="mdl-shadow--2dp" src={item.avatar} />
                           <span className="m-conversation-label">{item.full_name}</span>
                              </div>

                              <div className='dot' style={{display:'flex',alignItems:'center'}}>
                              <div className='hiddendots' style={{border:'0.001px solid rgba(0,0,0,0.2)',borderRadius:'50px',display:'flex',alignItems:'center',background:'white'}}>
                               <BsThreeDots style={{fontSize:'20px',margin:'5px'}}/>
                              </div>
                              <span className="m-conversation-icons"><span className="m-conversation-new mdl-color--green" hidden=""></span>
                           <span className="m-conversation-online mdl-color--blue"></span></span>
                              </div>
                           </div>
                        )) :
                        <p className="center">Search user</p>
                     }


                     
                  </div>
               
               </div>

                        <div className='sidebar-list' style={{color:"black",background:'#D8DADF',borderRadius:'50%',height:'40px',width:'40px',display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}}>
                   <i  className="fa fa-list" aria-hidden="true" style={{fontSize:"20px"}} onClick={this.handleOpen}></i>
                        <Drawer open={this.state.opensidebar} onClose={()=>this.setState({opensidebar:false})}>
                            <Sidebar close = {()=>this.setState({opensidebar:false})} data={this.state?.bannerImage}/>
                        </Drawer>
                    </div> 
                        
                      
                    
                  
                    </ul>
                   

                   
                </div>

                </div>


            </div>
        )
    }
}

export default withRouter(InnerHeader);