import React, { useState } from 'react'
import FreelancerNavbar from '../FreelancerNavbar'
import { Card, CardContent } from '@material-ui/core'
import Axios from 'axios';
import Cookies from 'js-cookie';

const DashboardPage = () => {
   const [chatUserData, setChatUserData] = useState([]);
    const cookieData = Cookies.getJSON('name');
  const selectUser = (item) => {
    console.log(item,'item of friend data')
     Axios.post(
         `${process.env.REACT_APP_URL}/api/users/start_chat`, 
         { 
             'user_id': cookieData?.user_data?.id, 
             'friend_id': item?.receiver_id, 
             'api_key': cookieData?.user_data?.api_key 
         }, 
         {  headers:{
           'Content-Type': 'text/plain',
           'Accept':'application/json, text/plain, */*'
         } }
     ).then((res) => {
         // On success
         if (res.data.code === true) {
             currentChating();
   
             setTimeout(() => {
                 const chatBox = document.getElementById(`scroll_chat_box${item?.receiver_id}`);
                 if (chatBox) {
                     chatBox.scrollTop = chatBox.scrollHeight;
                 }
             }, 1000);
         }
     }).catch((error) => {
         // Handle error
         console.error("Error starting chat:", error);
     });
   };
   
   const currentChating = async() => {
   
   const data = {
     "user_id":cookieData?.id,
     "api_key":cookieData?.api_key,
    
   }
   const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/current_chat`,
     data,
     {
       headers:{
         'Content-Type': 'text/plain',
         'Accept':'application/json, text/plain, */*'
       }
      });
   
      if(response.data.code === true){
          setChatUserData(response?.data)
      }
   };
  return (
    <div>
        <FreelancerNavbar selectUser={(item)=>selectUser(item)}/>
      <div style={{paddingTop:'90px',width:'100%',display:'flex',justifyContent:'center'}}>
        <div style={{width:'80%',display:'flex',gap:'40px'}}>
          <div style={{width:'65%'}}>
             <Card style={{height:'auto',width:'100%'}}>
                <CardContent>
                    <div style={{display:'flex',gap:'20px'}}>
                        <div style={{height:'60px',width:'60px',border:'1px solid black'}}></div>
                        <div>
                          <div>Divya K.. released a milestone payment of 100 USD for <spam>azure application ajdh kjsdb kjbskjd</spam></div>
                          <div>about 22 hours ago</div>

                          <div style={{fontWeight:'500',color:'black',marginTop:'20px'}}>Milestone Released</div>
                          <div>Detailed component level skdnh kjhsjd kjhkjsd kjdf.</div>
                          <button  style={{background:'#C40D6C',color:'white',height:'40px',padding:'5px 20px',borderRadius:'3px',marginTop:'10px',border:'none',fontSize:'20px',fontWeight:'500'}}>View Milestone</button>
                        </div>
                    </div>
                </CardContent>
             </Card>
          </div>

          <div style={{width:'30%'}}>
              <div style={{width:'100%',display:'flex',justifyContent:'space-between'}}>
                <div style={{fontSize:'18px',fontWeight:'500',color:'black'}}>Balance</div>
                <div style={{fontSize:'18px',fontWeight:'500',color:'#C40D6C'}}>+ Add Funds</div>
              </div>

              <div style={{width:'100%',display:'flex',justifyContent:'space-between',marginTop:'20px'}}>
                <div style={{fontSize:'18px'}}>US Dollar</div>
                <div style={{fontSize:'18px'}}>$000.00</div>
              </div>

              <hr></hr>


              <div>
                <img src='https://www.f-cdn.com/assets/main/en/assets/dashboard/widgets/zero-commission/zero-commission-v3.png'/>
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardPage
