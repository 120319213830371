import React, { useEffect, useState } from 'react'
import FreelancerNavbar from '../FreelancerNavbar'
import { IoMdShare } from "react-icons/io";
import ProjectDetails from './ProjectDetails';
import ClientInfo from './ClientInfo';
import ProjectProposals from './ProjectProposals';
import Pyments from './Pyments';
import ReviewPage from './ReviewPage';
import Axios from 'axios';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import Cookies from 'js-cookie';

const FreelancerProjects = () => {
    const [details,setDetails] = useState('details')
    const [cookieData, setCookieData] = useState({});
    const [detail,setDetail] = useState()
    const [load,setLoad] = useState(false);
    const [bidId,setBidId] = useState('')
    const params = useParams();
    const handleProjectDetail = async() => {
        if(Object.keys(cookieData).length > 0 ){
        const data = {
          "user_id":cookieData?.id,
          "api_key":cookieData?.api_key,
          "project_id":params?.projectname
        }
        const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/get_single_project`,
          data,
          {
            headers:{
              'Content-Type': 'text/plain',
              'Accept':'application/json, text/plain, */*'
            }
           });
           if(response){
            setDetail(response?.data?.response)
            setLoad(!load)
              console.log(response,'response')
              return true
           }
        }
      }

      const [bidsData,setBidsData] = useState();
 

  const handleProjectList = async() => {
    if(Object.keys(cookieData).length > 0 ){
    const data = {
      "project_id":detail?.id,
      "user_id":cookieData?.id,
      "api_key":cookieData?.api_key
    }
    const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/get_bid_by_project_id`,
      data,
      {
        headers:{
          'Content-Type': 'text/plain',
          'Accept':'application/json, text/plain, */*'
        }
       });
       if(response){
        setBidsData(response?.data?.response)
          console.log(response,'response')
          return true
       }
      }
  }

 

 
  useEffect(()=>{
      handleProjectList();
  },[details,params,load])

      // console.log(detail,'details')
      // console.log(params,'params')

      useEffect(()=>{
        handleProjectDetail();
      },[cookieData,params])

      useEffect(() => {

        let data = Cookies.getJSON('name');
        setCookieData(data?.user_data)
       
      
      }, []);
  return (
    <div>
    <FreelancerNavbar cookieData={cookieData} details={details} detail={detail}/>

    <div className='freelancer-name-div  freelancer-name-div2' style={{width:'80%',margin:"auto",paddingTop:"90px"}}>
        <div className='column' style={{display:"flex",alignItems:'center',justifyContent:"space-between"}}>
            <div style={{display:"flex",gap:"15px",alignItems:'center'}}>
                <h3 className='font-small' style={{color:"black"}}>{detail?.title}</h3>
                <div style={{padding:"3px 12px",color:"black",backgroundColor:"#DEFEE9", display:"flex",alignItems:"center",borderRadius:"20px",height:"30px"}}>Open</div>
            </div>
            <div  style={{display:"flex",gap:"8px"}}>
                <div>
                    <div>Bids</div>
                    <h4 className='font-small' style={{color:"black"}}>{bidsData?.length > 0 ? bidsData?.length : '0'} &bull;</h4>
                </div>
                <div>
                    <div>Avegrage Bid</div>
                    <h4 className='font-small' style={{color:"black"}}>$100 USD</h4>
                </div>
            </div>
        </div>

        <div style={{display:"flex",justifyContent:"space-between",alignItems:'center'}}>
            <div style={{display:'flex',gap:"8px"}}>
                <div onClick={()=>setDetails('details')} style={{...details === 'details' ?{color:"#1772EB",borderBottom:"2px solid #1772EB",}:{} , paddingBottom:"10px",cursor:"pointer",padding:"4px"}}>Details</div>
                <div onClick={()=>setDetails('proposals')} style={{...details === 'proposals' ?{color:"#1772EB",borderBottom:"2px solid #1772EB",}:{} ,padding:"4px", paddingBottom:"10px",cursor:"pointer"}}>Proposals</div>

                { detail?.user_id === cookieData?.id &&(
                <div onClick={()=>setDetails('payment')} style={{...details === 'payment' ?{color:"#1772EB",borderBottom:"2px solid #1772EB",}:{} ,padding:"4px", paddingBottom:"10px",cursor:"pointer"}}>Payment</div>)}

             { detail?.user_id === cookieData?.id &&(   
                <div onClick={()=>setDetails('reviews')} style={{...details === 'reviews' ?{color:"#1772EB",borderBottom:"2px solid #1772EB",}:{} ,padding:"4px", paddingBottom:"10px",cursor:"pointer"}}>Reviews</div>)}
            </div>
            <div>
                <IoMdShare style={{color:"black",fontSize:"24px"}}/>
            </div>
        </div>

        {
            details === 'details' &&
            (

                <div className='project-details-column' style={{display:'flex',paddingTop:"20px",width:"100%",gap:"20px"}}>
                    <div style={{display:"flex",width:"68%",}}>
                        <ProjectDetails detail={detail} cookieData={cookieData} bidsData={bidsData} bidId={bidId} details={details}/>
                    </div>
                    <div  style={{display:"flex",width:"28%",}}>
                        <ClientInfo/>
                    </div>
                </div>
            )}
            
           {details === 'proposals' && (
                
                <div style={{display:"flex",width:"100%"}}>
                <ProjectProposals bidsData={bidsData} cookieData={cookieData} detail={detail} setLoad={setLoad} load={load} 
                setDetails={setDetails} setBidId={setBidId} details={details}/>
                </div>
                )
        }



{details === 'payment' && (
                
                <div style={{display:"flex",width:"100%"}}>
                <Pyments cookieData={cookieData} detail={detail} details={details}/>
                </div>
                )}
        



{details === 'reviews' && (
                
                <div style={{display:"flex",width:"100%"}}>
                <ReviewPage/>
                </div>
                )}
                



    </div>
    </div>
  )
}

export default FreelancerProjects
