import { Avatar, Tooltip, } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { MdMenu, MdSubscriptions } from 'react-icons/md'
import Cookies from 'js-cookie';
import { IoNotifications } from "react-icons/io5";
import { FaFolderOpen } from "react-icons/fa";
import { MdChatBubble } from "react-icons/md";
import { TiArrowSortedUp } from "react-icons/ti";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import RecentProject from './RecentProject';
import Notifications from './Notifications';
import Messages from './Messages';
import Axios from 'axios';

const FreelancerNavbar = ({details,detail,selectUser}) => {
  const history = useHistory();
  const [chatUser, setChatUser] = useState();
  const [chatUserData, setChatUserData] = useState([]);
  const[friendId,setFriendId] = useState()
  const cookieData = Cookies.getJSON('name');
  // console.log(cookieData)
  const currentChatingData = async() => {
   try {
    
     const data = {
       "user_id":cookieData?.user_data?.id,
       "api_key":cookieData?.user_data?.api_key
     }
     const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/recent_messages`,
       data,
       {
         headers:{
           'Content-Type': 'text/plain',
           'Accept':'application/json, text/plain, */*'
         }
        });
 
        if(response.data.code === true){
            setChatUser(response?.data?.data)
        }
      
   } catch (error) {
    console.log(error,'error in chat api')
   }
    
};

useEffect(() => {
  currentChatingData()
},[])



console.log(chatUser,'chat')

// console.log("cookieData",cookieData)
const userEmail = cookieData && cookieData?.user_data.email && cookieData?.user_data.email.includes('@gmail.com') ? 
  cookieData.user_data?.email.replace('@gmail.com', '') : cookieData?.user_data.email
  return (
    <div>
        <div  style={{zIndex:"999",backgroundColor:"white",position:'fixed',width:"100%", display:"flex",justifyContent:"space-between",padding:"0px 10px",boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}>
         <div style={{display:'flex',alignItems:'center',gap:'10px',maxWidth:"100px"}}>
            <MdMenu id='MenuBarLogo'  style={{fontSize:'25px',cursor:"pointer"}}/>
           <img onClick={()=>history.push("/freelancer/dashboard")} className='vtubeLogo' 
           src="/victus.png" alt="" />
         </div>

         <div  style={{display:"flex" , alignItems:"center",position:"relative",}}>
            <div style={{display:"flex",alignItems:"center",marginRight:"20px",gap:"8px"}}>

              <div className='notify-div'>
              <IoNotifications  style={{fontSize:"25px",margin:"0 10px",cursor:'pointer'}}/>
              <div id='freelancerNotify' style={{position:'absolute'}}>
                <TiArrowSortedUp style={{position:"absolute",top:"-21px",height:"38px",width:"38px",color:"white"}}/>
                   <Notifications/>
                </div>
              </div>

              
              <div className='project-div'>
              <FaFolderOpen  style={{fontSize:"25px",margin:"0 10px",cursor:'pointer',position:'relative'}}/>
                <div id='freelanceProject' style={{position:'absolute'}}>
                <TiArrowSortedUp style={{position:"absolute",top:"-21px",height:"38px",width:"38px",color:"white"}}/>
                   <RecentProject detail={detail}/>
                </div>
              </div>
             
              <div className='message-div'>
              <MdChatBubble  style={{fontSize:"25px",cursor:'pointer'}}/>
              <div id='freelanceMessage' style={{position:'absolute'}}>
                <TiArrowSortedUp style={{position:"absolute",top:"-21px",height:"38px",width:"38px",color:"white"}}/>
                   <Messages chatUser={chatUser} cookieData={cookieData} setFriendId={setFriendId} selectUser={(item)=>selectUser(item)}/>
                </div>
              </div>
            </div>


            <button className='projectButton' onClick={()=>history.push('/post-project')} style={{background:'#C40D6C',color:'white',height:'40px',padding:'5px 20px',borderRadius:'3px',border:'none',fontSize:'16px',fontWeight:'500',marginRight:'10px'}}>Post a Project</button>


            <div className='profile-div' style={{display:"flex",alignItems:"center"}}>
              <Avatar alt={cookieData &&cookieData?.user_data.full_name} src="/static/images/avatar/1.jpg" style={{cursor:"pointer"}} />
              <div style={{marginLeft:"6px",cursor:"pointer"}}>
              <h5 style={{ margin: "0" }}>
 @ {cookieData && cookieData?.user_data.email && cookieData?.user_data.email.includes('@gmail.com') ? 
    cookieData.user_data?.email.replace('@gmail.com', '') : cookieData?.user_data.email}
</h5>
                <p style={{margin:"0"}}>$ 0.00 USD</p>
              </div>
              
              <div id='freelancer-profile'>
                <TiArrowSortedUp style={{position:"absolute",top:"-21px",right:"38px",height:"38px",width:"38px",color:"white"}}/>
                <h5>Account</h5>
                <div onClick={()=>history.push(`/u/${cookieData?.user_data.id}`)} style={{color:"black",marginTop:"5px",cursor:"pointer"}}>View Profile</div>
                <div onClick={()=>history.push(`/u/${cookieData?.user_data.id}`)} style={{color:"black",marginTop:"5px",cursor:"pointer"}}>Membership</div>
                <div onClick={()=>history.push(`/dashboard`)} style={{color:"black",marginTop:"5px",cursor:"pointer"}}>Dashboard</div>
                <div onClick={()=>history.push(`/freelancer/myProjects`)} style={{color:"black",marginTop:"5px",cursor:"pointer"}}>My Projects</div>
                <div onClick={()=>history.push(`/freelancer/insightbids`)} style={{color:"black",marginTop:"5px",cursor:"pointer"}}>Insight Bids</div>
                <div onClick={()=>history.push(`/freelancer/settings/profile`)} style={{color:"black",marginTop:"5px",cursor:"pointer"}}>Setting</div>
                <div onClick={()=>history.push(`/u/ankit`)} style={{color:"black",marginTop:"5px",cursor:"pointer"}}>Balance $500</div>
                <div onClick={()=>history.push(`/u/ankit`)} style={{color:"black",marginTop:"5px",cursor:"pointer"}}>Logout</div>
              </div>
            </div>

         </div>
    </div>
      
    </div>
  )
}

export default FreelancerNavbar
