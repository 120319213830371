import { Button, TextField } from '@material-ui/core'
import Axios from 'axios';
import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie';

const ChannelDialog = ({setOpen,setLoad}) => {
    const[channelname,setChannelname] = useState('')
    const [cookieData, setCookieData] = useState({});
   
    const handleAddChannel = async() => {
      try {
        if(Object.keys(cookieData).length > 0 ){
        let formdata = new FormData()
        formdata.append('api_key',cookieData?.api_key)
        formdata.append('user_id',cookieData?.id)
        formdata.append('channel_name',channelname)
          const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/create_channel`,
              formdata,
              {
                headers:{
                  'Content-Type': 'text/plain',
                  'Accept':'application/json, text/plain, */*'
                }
               });
               if(response.data.code === true){
                  setChannelname('');
                  setOpen(false);
                  setLoad(true);
               }
              }
      } catch (error) {
        console.log(error,'error in channel api')
      }
    }

    useEffect(() => {

        let data = Cookies.getJSON('name');
        setCookieData(data?.user_data) 
       
      }, []);
  return (
    <div style={{width:'500px',height:'auto',padding:'20px'}}>
        <div style={{fontSize:'22px',textAlign:'center'}}>Create your channel name</div>
        <div style={{fontSize:'14px'}}>You can use your brand's name or another name. A good channel name represents you and your content. You can change your channel name at any time</div>

        <TextField variant='outlined' fullWidth label='Channel name' value={channelname} onChange={(e)=>setChannelname(e.target.value)} style={{marginTop:'20px'}}/>

        <div style={{display:'flex',gap:'10px',justifyContent:'center',marginTop:'20px'}}>
            <Button onClick={()=>setOpen(false)} variant='outlined'>Cancel</Button>
            <Button onClick={handleAddChannel} variant='contained'>Create</Button>
        </div>
    </div>
  )
}

export default ChannelDialog
