import React, { useState } from 'react'
import { GoHomeFill } from "react-icons/go";
import { PiTelevisionSimpleBold } from "react-icons/pi";
import { SiYoutubeshorts } from "react-icons/si";
import { MdMenu, MdSubscriptions } from "react-icons/md";
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { MdOutlineDashboard } from "react-icons/md";



const VtubeSidebar = ({open,setOpen}) => {

    const [value,setValue] = useState(0)
    const history = useHistory();
    
  return (
    <div className='sideBar'>
        <div id='smallSideBar' style={!open ? {display:"flex"} : {display:"none"}}>
            <div style={{height:"85vh",width:"74px",display:"flex",flexDirection:"column",alignItems:"center",marginTop:"70px"}}>
                
                <div className='SidebarButtonsHover'>
                    <div  onClick={()=>{history.push("/vtube");setValue(0)}}  style={{padding:"16px 0 14px",display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center",width:"100%",...(value === 0 && {backgroundColor:"rgb(228, 225, 225)",borderRadius:"8px"}) }}>
                        <GoHomeFill color='black' size={24}/>
                        <p style={{margin:"0",color:"black",fontSize:"10px"}}>Home</p>
                    </div>
                </div>

                <div className='SidebarButtonsHover'>
                    <div onClick={()=>{setValue(1);history.push("/reel")}}  style={{padding:"16px 0 14px",display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center",width:"100%",...(value == 1 && {backgroundColor:"rgb(228, 225, 225)",borderRadius:"8px"})}} >
                        <SiYoutubeshorts color='black' size={24}/>
                        <p style={{margin:"0",color:"black",fontSize:"10px"}}>Shorts</p>
                    </div>
                </div>

                {/* <div className='SidebarButtonsHover'>
                    <div onClick={()=>setValue(2)}  style={{padding:"16px 0 14px",display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center",width:"100%",...(value == 2 && {backgroundColor:"rgb(228, 225, 225)",borderRadius:"8px"})}} >
                    <MdSubscriptions color='black' size={24}/>
                    <p style={{margin:"0",color:"black",fontSize:"10px"}}>Subscription</p>
                    </div>
                </div> */}

                <div className='SidebarButtonsHover'>
                    <div onClick={()=>{setValue(3);history.push("/allchannel")}} style={{padding:"16px 0 14px",display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center",width:"100%",...(value == 3 && {backgroundColor:"rgb(228, 225, 225)",borderRadius:"8px"})}} >
                    <PiTelevisionSimpleBold color='black'  size={24}/>
                    <p style={{margin:"0",color:"black",fontSize:"10px"}}>All Channel</p>
                    </div>
                </div>
                
                <div className='SidebarButtonsHover'>
                    <div onClick={()=>{setValue(4);history.push("/dashboard")}} style={{padding:"16px 0 14px",display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center",width:"100%",...(value == 4 && {backgroundColor:"rgb(228, 225, 225)",borderRadius:"8px"})}} >
                    <MdOutlineDashboard color='black'  size={24}/>
                    <p style={{margin:"0",color:"black",fontSize:"10px"}}>Dashboard</p>
                    </div>
                </div>

            </div>

        </div>

        <div id='LargrSideBar' style={open ? {display:"flex"} : {display:"none"}}>
            <Box>
            <div style={{display:'flex',alignItems:'center',gap:'10px',marginLeft:'10px'}}>
            <MdMenu id='MenuBarLogo' onClick={()=>setOpen(false)}  style={{fontSize:'25px',cursor:"pointer"}}/>
           <img className='vtubeLogo' src="/victus.png" alt="" />
         </div>
            <div className='LargeSide' style={{height:"85vh",width:"240px",display:"flex",flexDirection:"column",alignItems:"center",marginTop:'30px'}}>

                <div className='SidebarButtonsHover'>
                    <div onClick={()=>{history.push("/vtube");setValue(0)}} style={{padding:"10px 12px",display:"flex",alignItems:"center",backgroundColor:"rgb(228, 225, 225)",gap:"15px",borderRadius:"8px",width:"100%",cursor:"pointer",margin:"1px 0",
                    ...(value === 0 && {backgroundColor:"rgb(228, 225, 225)",borderRadius:"8px"})}}>
                        <GoHomeFill color='black' size={24}/>
                        <p style={{margin:"0",color:"black",fontSize:"14px"}}>Home</p>
                    </div>

                </div>
                
                <div className='SidebarButtonsHover'>
                    <div onClick={()=>{setValue(1);history.push("/reel")}} style={{padding:"10px 12px",display:"flex",alignItems:"center",gap:"15px",borderRadius:"8px",width:"100%",cursor:"pointer",margin:"1px 0",
                    ...(value === 1 && {backgroundColor:"rgb(228, 225, 225)",borderRadius:"8px"})}}>
                        <SiYoutubeshorts color='black' size={24}/>
                        <p style={{margin:"0",color:"black",fontSize:"14px"}}>Shorts</p>
                    </div>
                </div>

                {/* <div className='SidebarButtonsHover'>
                    <div onClick={()=>setValue(2)} style={{padding:"10px 12px",display:"flex",alignItems:"center",gap:"15px",borderRadius:"8px",width:"100%",cursor:"pointer",margin:"1px 0",
                    ...(value === 2 && {backgroundColor:"rgb(228, 225, 225)",borderRadius:"8px"})}}>
                        <MdSubscriptions color='black' size={24}/>
                        <p style={{margin:"0",color:"black",fontSize:"14px"}}>Subscription</p>
                    </div>
                </div> */}
                
                <div className='SidebarButtonsHover'>
                    <div onClick={()=>{setValue(3);history.push("/allchannel")}} style={{padding:"10px 12px",display:"flex",alignItems:"center",gap:"15px",borderRadius:"8px",width:"100%",cursor:"pointer",margin:"1px 0",
                    ...(value === 3 && {backgroundColor:"rgb(228, 225, 225)",borderRadius:"8px"})}}>
                        <PiTelevisionSimpleBold color='black'  size={24}/>
                        <p style={{margin:"0",color:"black",fontSize:"14px"}}>All Channel</p>
                    </div>
                </div>
                
                <div className='SidebarButtonsHover'>
                    <div onClick={()=>{setValue(3);history.push("/dashboard")}} style={{padding:"10px 12px",display:"flex",alignItems:"center",gap:"15px",borderRadius:"8px",width:"100%",cursor:"pointer",margin:"1px 0",
                    ...(value === 3 && {backgroundColor:"rgb(228, 225, 225)",borderRadius:"8px"})}}>
                        <MdOutlineDashboard color='black'  size={24}/>
                        <p style={{margin:"0",color:"black",fontSize:"14px"}}>Dashboard</p>
                    </div>
                </div>
            </div>

            </Box>

        </div>
      
    </div>
  )
}

export default VtubeSidebar
