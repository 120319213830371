import { Avatar, Button, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { AiOutlineDelete } from "react-icons/ai";
import Axios from "axios";
import Cookies from "js-cookie";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const DeleteChannel = ({ setOpen, channelId, data }) => {
  const [cookieData, setCookieData] = useState({});
  const [channel, setChannel] = useState(data?.name);
  const history = useHistory();

  useEffect(() => {
    let cookie = Cookies.getJSON("name");
    setCookieData(cookie?.user_data);
  }, []);

  const updateChannelData = async () => {
    let requestData = {
      user_id: cookieData?.id,
      api_key: cookieData?.api_key,
      channel_id: channelId,
    
    };

    const response = await Axios.post(
      `${process.env.REACT_APP_URL}/api/users/delete_channel`,
      requestData,
      {
        headers: {
          "Content-Type": "text/plain",
          Accept: "application/json, text/plain, */*",
        },
      }
    );

    if (response.data.code === true) {
        history.push('/vtube')
      setOpen(false);
      return true;
    }
  };

  return (
    <div style={{ padding: "20px", textAlign: "center", maxWidth: "400px" }}>
      {/* Icon */}
      <div style={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}>
        <AiOutlineDelete size={50} color="red" />
      </div>

      {/* Title */}
      <Typography variant="h6" style={{ fontWeight: "bold" }}>
        Are you sure you want to delete this channel?
      </Typography>
      
      {/* Subtitle */}
      <Typography variant="body2" color="textSecondary" style={{ marginBottom: "20px" }}>
        This action cannot be undone.
      </Typography>

      {/* Channel Name Input */}
      {/* <TextField
        value={channel}
        onChange={(e) => setChannel(e.target.value)}
        fullWidth
        variant="outlined"
        placeholder="Channel name"
        style={{ marginBottom: "20px" }}
      /> */}

      {/* Buttons */}
      <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
        <Button onClick={() => setOpen(false)} variant="outlined">
          Cancel
        </Button>
        <Button onClick={updateChannelData} variant="contained" color="secondary">
          Delete
        </Button>
      </div>
    </div>
  );
};

export default DeleteChannel;
