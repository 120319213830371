import { Avatar, } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { IoMdArrowBack } from "react-icons/io";
import { IoSearch } from 'react-icons/io5'
import { MdMenu, MdSubscriptions } from 'react-icons/md'
import { RiVideoAddLine } from 'react-icons/ri'
import VtubeUploadVideo from './VtubeUploadVideo';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import VtubeSearchBar from './VtubeSearchBar';
import { GoHomeFill } from 'react-icons/go';
import { SiYoutubeshorts } from 'react-icons/si';
import { PiTelevisionSimpleBold } from 'react-icons/pi';
import Cookies from 'js-cookie';
import Axios from 'axios';

const Dialog = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="dialog-overlay">
      <div className="dialog-content" id='UploadVideo_dialog' style={{padding:'20px',width:"70%",height:"70vh",overflowX:'auto'}}>
        {children}
      </div>
    </div>
  );
};

const VtubeNavbar = ({open,setOpen,setLoad,channelId,load,query}) => {
  
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [openSearch , setOpenSearch] = useState(false)
  const[fileType,setFileType] = useState()
  const [cookieData, setCookieData] = useState({});
  const[channelData,setChannelData] = useState({})
    const[message,setMessage] = useState('')
    const history = useHistory()

console.log(query,'query')


  const handleOpen=() =>{
    open ? setOpen(false) : setOpen(true)
  }
    const closeDialog = () => {
        setIsDialogOpen(false);
      };
    const handleOpenSearch=() =>{
      openSearch ? setOpenSearch(false) : setOpenSearch(true)
      console.log("clicked")
    } 

    useEffect( ()=>{
      const getdata =async()=>{
        const data = await fetch("http://localhost/backend/api/users/get_video",{
          "user_id":"65",
          "api_key":"0db49b7e4d53605850981ab51ecec96e",
          "video_id":"2"
      })
      console.log(data)
        
      } 
    })

   


    useEffect(() => {

      let data = Cookies.getJSON('name');
      setCookieData(data?.user_data) 
     
    }, []);

    useEffect(() => {

      let data2 = Cookies.getJSON('channel');
      setChannelData(data2) 
     
    }, [load,channelId]);

    const stringAvatar = (name) => {
      const nameParts = name.split(' ');
      return {
        children: `${nameParts[0][0]}${nameParts[1] ? nameParts[1][0] : ''}`,
      };
    }

  return (

    <div >

   
    <div className='vtubeNav' style={{zIndex:"999",backgroundColor:"white",position:'fixed'}}>
         <div style={{display:'flex',alignItems:'center',gap:'10px'}}>
            <MdMenu id='MenuBarLogo' onClick={handleOpen} style={{fontSize:'25px',cursor:"pointer"}}/>
           <img className='vtubeLogo' src="/victus.png" alt="" />
         </div>

         <div className='SearchInput' style={{width:'46%',display:'flex',alignItems:'center',position:'relative'}}>
            <VtubeSearchBar/>
         </div>

            <div  style={{width:'100%',height:"100vh",display:"flex",position:'relative',backgroundColor:"white",position:"fixed",top:"0",left:"0",right:"0",zIndex:"99999",padding:"15px",gap:"20px",
              ...(openSearch ?{display:"flex"}:{display:"none"})}}>
                <IoMdArrowBack onClick={handleOpenSearch} color='black' style={{fontSize:'25px',marginTop:"7px"}}/>
            <VtubeSearchBar query={query}/>
            </div>

         <div style={{display:'flex',alignItems:'center',gap:'20px'}}>

            <IoSearch onClick={handleOpenSearch} id='SearchInputIcon' style={{fontSize:'25px',display:"none"}}/>


              <div  onClick={()=>setIsDialogOpen(true)} style={{display:'flex',alignItems:'center',gap:'4px',cursor:"pointer",backgroundColor:'#F2F2F2',padding:"4px 8px" , borderRadius:'20px'}}>
                  <RiVideoAddLine style={{fontSize:'25px'}}/>
                    <span style={{fontWeight:'500'}}>Create</span>
              </div>
              <Dialog isOpen={isDialogOpen} onClose={closeDialog}>
                          <VtubeUploadVideo isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} setLoad={setLoad}/>
              </Dialog>
            <div className='mychannelAvatar' style={{position:"relative"}}>
              <Avatar src={channelData?.file} onClick={()=>history.push(`/mychannel/${channelData?.id}`)} style={{cursor: "pointer",background:'green'}} 
              {...stringAvatar(`${channelData?.name}`)} />
                <span id='mychannel' style={{backgroundColor:"#F2F2F2",color:"black",padding:"5px",borderRadius:'5px',position:"absolute",top:"39px",left:"-33px",display:"none",width:"96px"}}>My Channel</span>
            
            </div>  
         </div>
    </div>

    
    </div>
  )
}

export default VtubeNavbar
