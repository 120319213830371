import React, { useState } from "react";
import moment from "moment";
import Axios from "axios";

const Messages = ({ chatUser,cookieData,setFriendId,selectUser }) => {
  
 

const handleFriendId = (item) => {
   setFriendId(item)
}
  return (
    <div style={{ width: "100%" }}>
      <div style={{ width: "100%" }}>
        <div style={{ padding: "20px", width: "100%" }}>
          <div style={{ fontSize: "18px", fontWeight: "500", color: "black", textAlign: "left" }}>
            Recent Messages
          </div>

          {chatUser && chatUser.length > 0 ? (
            chatUser.map((chat, index) => {
              const formattedDate = moment(chat?.datetime).format("MMM D, YYYY"); // Feb 1, 2025
              const isToday = moment(chat?.datetime).isSame(moment(), "day");
              const isYesterday = moment(chat?.datetime).isSame(moment().subtract(1, "day"), "day");

              let displayDate = formattedDate;
              if (isToday) displayDate = "Today";
              else if (isYesterday) displayDate = "Yesterday";

              const timeAgo = moment(chat?.datetime).fromNow(); // "2 minutes ago"

              return (
                <div onClick={()=>selectUser(chat)} key={index} style={{ display: "flex", gap: "20px", marginTop: "10px", width: "100%",cursor:'pointer' }}>
                  <div style={{ height: "50px", width: "50px", border: "1px solid black", overflow: "hidden" }}>
                    <img src={chat?.avatar} alt="avatar" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                  </div>
                  <div style={{ width: "100%" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                      <div style={{ color: "black", fontSize: "14px" }}>@{chat?.receiver_name}</div>
                      <div style={{ fontSize: "12px" }}>{displayDate}</div>
                    </div>
                    {/* <div style={{ fontSize: "12px", color: "gray" }}></div> */}
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "280px",
                        fontSize: "15px",
                        marginLeft: "10px",
                      }}
                    >
                      {chat?.message}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div style={{ marginTop: "10px", color: "gray", fontSize: "14px" }}>No messages available</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Messages;
