import React, { useEffect, useState } from 'react';
import Header from './header';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import toastr from 'reactjs-toastr';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import ReCAPTCHA from 'react-google-recaptcha'; // Import reCAPTCHA

function Login() {
  const history = useHistory();
  const location = useLocation();
  const code = location?.search?.split('=')[1];
  const [isLoading, setIsLoading] = useState(false);
  const [cookieData, setCookieData] = useState({});
  const [channelData, setChannelData] = useState();
  const [load, setLoad] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(''); // State to store CAPTCHA token

  const initialValues = {
    email: '',
    password: '',
    email_auth_code: code || '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email format').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const headers = {
    'Content-Type': 'text/plain',
  };

  const handleSubmit = async (values) => {
    if (!captchaToken) {
      toastr.error('Please complete the CAPTCHA before logging in.', { displayDuration: 3000 });
      return;
    }

    setIsLoading(true);

    const requestData = {
      ...values,
      captchaToken, // Include CAPTCHA token in the request
    };

    await axios.post(`${process.env.REACT_APP_URL}/api/users/login`, requestData, { headers })
      .then(response => {
        if (response.data.code === true) {
          toastr.success('Success', { displayDuration: 3000 });
          Cookies.set('name', response.data);
          setIsLoading(false);

          if (response?.data?.user_data?.is_enable_google_auth_code === '0' || Object.keys(cookieData).length > 0) {
            setLoad(!load);
            getChannelData();
            window.location.hash = '/dashboard';
          } else if (response?.data?.user_data?.is_enable_google_auth_code === '1') {
            window.location.hash = '/twofa';
          }
        } else if (response?.data?.code === false) {
          toastr.error(response.data.message, { displayDuration: 3000 });
        }
      })
      .catch(err => {
        console.error(err);
        toastr.error('An error occurred. Please try again.', { displayDuration: 3000 });
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (code) {
      toastr.success('Your email successfully verified', { displayDuration: 3000 });
      getChannelData();
    }
  }, [code]);

  const getChannelData = async () => {
    const data = {
      api_key: cookieData?.api_key,
    };

    const response = await axios.post(`${process.env.REACT_APP_URL}/api/users/get_channels`,
      data,
      {
        headers: {
          'Content-Type': 'text/plain',
          Accept: 'application/json, text/plain, */*',
        },
      });

    if (response.data.code === true) {
      setChannelData(response?.data?.response[0]);
      Cookies.set('channel', response?.data?.response[0]);
    }
  };

  useEffect(() => {
    getChannelData();
  }, [cookieData, load]);

  useEffect(() => {
    const data = Cookies.getJSON('name');
    setCookieData(data?.user_data);
  }, [load]);

  const onCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  return (
    <div style={{ height: '100%', overflow: 'hidden', backgroundColor: "#323132" }}>
      <div>
        {/* Header or additional content */}
      </div>
      <div style={{ backgroundColor: "#0E0E10", minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
        <form style={{ maxWidth: "600px", backgroundColor: "#18181B", color: "#fff", padding: "30px", width: "100%" }} onSubmit={formik.handleSubmit}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Link to="/">
              <img className="img-responsive" alt="Logo" src="/victus.png" style={{ width: "70px", height: "70px", borderRadius: "100%" }} />
            </Link>
            <h3 className="purple oR m0">Log in to Victus</h3>
          </div>
          <div style={{ marginTop: "20px" }}>
            <div className='form-container'>
              <strong>Username</strong>
              <input
                placeholder="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="email"
                className='input-field'
                type="email"
              />
            </div>
            <span className="errorMessage_signup">{formik.touched.email && formik.errors.email}</span>
            <div className='form-container'>
              <strong>Password</strong>
              <input
                placeholder="Password"
                type="password"
                value={formik.values.password}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                name="password"
                className='input-field'
              />
            </div>
            <span className="errorMessage_signup">{formik.touched.password && formik.errors.password}</span>
          </div>
          <div style={{ marginTop: "20px" }}>
            <ReCAPTCHA
              sitekey="6Lcx49kqAAAAABflcOLTeJA88gcOKf99K0LhHF6R" // Replace with your actual Site Key
              onChange={onCaptchaChange}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}>
            <span style={{ color: "#AE8A3D" }}>
              <Link to="/forgetpassword">Forgot your password?</Link>
            </span>
            <button
              type="submit"
              className="btn btn-gradient W100"
              style={{ marginTop: "20px" }}
              disabled={!formik.isValid || !captchaToken} // Disable button until CAPTCHA is completed
            >
              {isLoading ? (
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  <CircularProgressBar />
                  <div>Login</div>
                </div>
              ) : 'Login'}
            </button>
            <p style={{
              textAlign: "center",
              fontSize: "20px",
              color: "#fff"
            }}>Don't have an account? <Link to="/register" style={{ color: "#AE8A3D" }}>Register</Link></p>
          </div>
        </form>
        <div style={{ textAlign: "center", padding: "10px", color: "white", backgroundColor: "#323132", width: "100%", maxWidth: "600px" }}>Audentis fortuna iuvat</div>
      </div>
    </div>
  );
}

const CircularProgressBar = () => {
  return (
    <div className="circular-progress">
      <div className="circular-progress-bar"></div>
    </div>
  );
};

export default Login;
