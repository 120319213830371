import React from 'react'
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import StepThree from './StepThree'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'

const HireFreelancer = () => {
    const history = useHistory();
  return (
    <div style={{height:"100vh",width:"100%", display:'flex'}}>
        <div id='post-projectText' >
            <div style={{width:"90%",color:"black",margin:"auto",paddingTop:"40px"}}>
                <div>
                    <img className='vtubeLogo' src="/victus.png" alt="" />
                </div>
                <div id='post-projectText2' style={{color:"black",paddingTop:"20px"}}>
                Tell us what you need <span style={{color:"#D3B06B"}}>done.</span>
                </div>

                

                <div style={{padding:"20px 0"}}>
                    
                    <StepOne/>
                   
                </div>

               

                <div style={{padding:"40px 0"}}>
                    <div style={{fontSize:"18px"}}>
                    Freelancer connects over 77 million professionals globally
                    </div>
                    
                    <ul>
                        <li style={{fontSize:"18px",paddingTop:"12px"}}>From $100 tasks to $100 million projects, we've got you covered</li>
                        <li style={{fontSize:"18px",paddingTop:"12px"}}>Connect with skilled freelancers in seconds</li>
                        <li style={{fontSize:"18px",paddingTop:"12px"}}>Only pay freelancers once you are happy with their work</li>
                    </ul>
                </div>
            </div>
        </div>
        <div id='post-projectImage' >
            <img src="https://www.f-cdn.com/assets/main/en/assets/hire/hero/mobile/hero-01.jpg" alt=""
            style={{width:"100%",height:"100%",objectFit:"cover"}} />
        </div>
    </div>
  )
}

export default HireFreelancer
