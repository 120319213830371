import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import Axios from 'axios';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const StepOne = () => {
    const history = useHistory();
    const [cookieData, setCookieData] = useState({});
    const [projectType, setProjectType] = useState('');
    const [project,setProject] = useState('')
    const [pay,setPay] = useState('')
    const [cost,setCost] = useState('')
    const [skills, setSkills] = useState([]);
    const [skillInput, setSkillInput] = useState('');
  
    const handleKeyDown = (e) => {
      if (e.key === 'Enter' && skillInput.trim()) {
        e.preventDefault(); // Prevents line break on Enter
        setSkills([...skills, skillInput.trim()]); // Adds the skill to the array
        setSkillInput(''); // Clears the input field
      }
    };
  
    const handleInputChange = (e) => {
      setSkillInput(e.target.value);
    };
    const handleRemoveSkill = (index) => {
        setSkills(skills.filter((_, i) => i !== index));
      };

    // console.log('pay',pay)

    const handleSelectProject = (e) => {
      setProjectType(e.target.value);
    };


    const handleProject = (e) => {
        setProject(e.target.value)
    }
 
    const handlePay = (e) => {
        console.log(e,'eee')
       setPay(e.target.value)
    }
    const handleCost = (e) => {
        setCost(e.target.value)
    }

    console.log(cost,'cost')
 
    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
      const selectedFile = e.target.files[0];
      if (selectedFile && selectedFile.size <= 25 * 1024 * 1024) { // 25 MB max file size
        setFile(selectedFile);
      } else {
        alert("File size exceeds 25 MB.");
      }
    };
    

    const initialValue = {
        description:'',
        cost_estimation:'',
        title:'',
        status:'open',
       
    }

    const{handleBlur,handleChange,handleSubmit,values} = useFormik({
          initialValues:initialValue,
          onSubmit: async(values,action) => {
            const{description,title,status} = values
            if(Object.keys(cookieData).length > 0 && cost ){
                let formdata = new FormData();
                formdata.append('description',description)
                formdata.append('skills_required',JSON.stringify(skills))
                formdata.append('title',title)
                formdata.append('status',status)
                formdata.append('api_key',cookieData?.api_key)
                formdata.append('user_id',cookieData?.id)
                formdata.append('project_industry',projectType)
                formdata.append('project_purpose',project)
                formdata.append('pay_by',pay)
                formdata.append('cost_estimation',cost)
                formdata.append('file',file)

            const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/add_project`,
                formdata,
                {
                  headers:{
                    'Content-Type': 'text/plain',
                    'Accept':'application/json, text/plain, */*'
                  }
                 });
                 if(response){
                    history.push(`projects/${response?.data?.data}`)
                    action.resetForm()
                    console.log(response.data,'response')
                    return true
                 }
                }else{
                  alert('Select cost estimation')
                }
          }
    })


    useEffect(() => {

        let data = Cookies.getJSON('name');
        setCookieData(data?.user_data)
       
      
      }, []);
  return (
    <div>
      
      <div>
        <form onSubmit={handleSubmit} action="" style={{display:"flex",justifyContent:"space-between",flexDirection:"column",gap:"8px",}}>

        <div style={{color:"black",padding:"10px 0",fontSize:"18px"}}>
                We'll guide you to create the perfect brief. The more detail the better.
                </div>
        <textarea required rows="4" placeholder='Enter  full description' name='description' value={values.description} onChange={handleChange} onBlur={handleBlur} style={{border:"1px solid black",resize: "vertical", width: "100%", borderRadius:"5px",fontSize:"18px"}}></textarea>

        <div style={{fontSize:'18px', fontWeight:"600",padding:"20px 0",marginTop:"10px"}}>What kind of project is this?</div>

            <div style={{display:"flex",justifyContent:"space-between",flexWrap:"wrap"}}>
                <label htmlFor="" id='post-projectRadio' >
                    <input type="radio" name="kind" value="Website" onChange={handleSelectProject} checked={projectType === 'Website'} style={{width:"20px",margin:"0 10px"}} />
                    Website
                </label>
                <label htmlFor="" id='post-projectRadio'>
                    <input type="radio" name="kind" value="Mobile App" onChange={handleSelectProject} checked={projectType === 'Mobile App'} style={{width:"20px",margin:"0 10px"}} />
                    Mobile App
                </label>
                <label htmlFor="" id='post-projectRadio'>
                    <input type="radio" name="kind" value="Figma Design" onChange={handleSelectProject} checked={projectType === 'Figma Design'} style={{width:"20px",margin:"0 10px"}} />
                    Figma Design
                </label>
                
            </div>
            <input type="text" placeholder="Others" id='post-projectRadio' value={projectType} onChange={handleSelectProject} style={{width:"100%",justifyContent:'start',padding:"10px", borderRadius:"5px",fontSize:"18px",fontWeight:"500",color:"black"}} />

            <div style={{fontSize:'18px', fontWeight:"600",padding:"20px 0",marginTop:"10px"}}>What type of website do you need?</div>
            <div style={{display:"flex",justifyContent:"space-between",flexWrap:"wrap"}}>
                <label htmlFor="" id='post-projectRadio' >
                    <input type="radio" name="project" value="E-commerce" onChange={handleProject} checked={project === 'E-commerce'} style={{width:"20px",margin:"0 10px"}} />
                    E-commerce
                </label>
                <label htmlFor="" id='post-projectRadio'>
                    <input type="radio" name="project" value="Vlogging" onChange={handleProject} checked={project === 'Vlogging'} style={{width:"20px",margin:"0 10px"}} />
                    Vlogging
                </label>
                <label htmlFor="" id='post-projectRadio'>
                    <input type="radio" name="project" value="Social Media" onChange={handleProject} checked={project === 'Social Media'} style={{width:"20px",margin:"0 10px"}} />
                    Social Media
                </label>
                
            </div>
            <input type="text" placeholder="Others" id='post-projectRadio' value={project} onChange={handleProject} style={{width:"100%",justifyContent:'start',padding:"10px", borderRadius:"5px",fontSize:"18px",fontWeight:"500",color:"black"}} />

            <div style={{fontSize:'18px', fontWeight:"600",paddingTop:"20px",marginTop:"10px"}}>Project title</div>

<input required type="text" placeholder="Project title" name='title' value={values.title} onChange={handleChange} onBlur={handleBlur} style={{width:"100%",justifyContent:'start',padding:"10px", borderRadius:"5px",fontSize:"18px",fontWeight:"600",color:"black",border:"1px solid gray",marginTop:"4px"}} />


          <div class="upload-box" style={{flexDirection:'column'}}>

            <div style={{display:'flex'}}>
                <div class="icon">
                  <img src="https://img.icons8.com/ios-glyphs/30/000000/attach.png" alt="" />
                    {/* <img src="https://img.icons8.com/ios-glyphs/30/000000/attach.png" alt="Attachment Icon"> */}
                </div>
                <div class="text">
                    Drag & drop any images or documents that might be helpful in explaining your brief here. (Max 25 MB)
                </div>   
            </div>
                
      <input 
        type="file" 
        onChange={handleFileChange} 
        style={{ display: 'none' }} 
        id="fileUpload" 
        accept="image/*,.pdf,.doc,.docx"
      />
      <label 
        htmlFor="fileUpload" 
        style={{ color: '#007bff', cursor: 'pointer', marginTop: '10px', display: 'inline-block' }}
      >
        {file ? `Selected: ${file.name}` : "Or select a file"}
      </label>
              </div>


              <div class="skills-section">
                <h5 >What skills are required?</h5>
                <p style={{color:"black"}}>Enter up to 5 skills that best describe your project. Freelancers will use these skills to find projects they are most interested and experienced in.</p>
                <div class="selected-skills">
                    <textarea  rows="1" placeholder='Enter skills required' name='skills_required' 
                    value={skillInput} onChange={handleInputChange} onKeyDown={handleKeyDown}  style={{border:"1px solid #ccc",resize: "vertical", width: "100%", borderRadius:"5px",fontSize:"18px"}}></textarea>
                  
                </div>
                <div className="skills-list">
        {skills.map((skill, index) => (
          <span
            key={index}
            className="skill-item"
            onClick={() => handleRemoveSkill(index)}
            style={{
              display: 'inline-block',
              margin: '5px',
              padding: '5px 10px',
              backgroundColor: '#f0f0f0',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            {skill} &times;
          </span>
        ))}
      </div>
                <p class="suggested-skills">Suggested skills: MySQL, Web Hosting, Software Testing, Engineering, Software Architecture</p>
            </div>


            {/* <div class="get-it-done-section">
                <h5>How would you like to get it done?</h5>
                <div class="get-it-done-options">
                    <label class="option-card recommended">
                        <div class="icon">
                            <img src="https://img.icons8.com/ios-filled/50/000000/desktop.png" alt="Post a project"/></div>
                        <h3>Post a project</h3>
                        <p>Receive free quotes. Best for when you have a specific idea, the project is not visual in nature or the project is complex.</p>
                        <input type="radio" name="projectType" value="Post a project" checked/>
                    </label>
                    <label class="option-card">
                        <div class="icon"><img src="https://img.icons8.com/ios-filled/50/000000/trophy.png" alt="Start a contest"/></div>
                        <h3>Start a contest</h3>
                        <p>Crowdsource ideas. Post a prize and get competing entries that you can iterate on with feedback. Great for visual designs.</p>
                        <input type="radio" name="projectType" value="Start a contest"/>
                    </label>
                </div>
            </div> */}


            <div class="payment-section">
                <h5>How do you want to pay?</h5>
                <div class="payment-options">
                    <label class="option-card">
                        <div class="icon"><img src="https://img.icons8.com/ios-filled/50/000000/wall-clock.png" alt="Pay by the hour"/></div>
                        <h3>Pay by the hour</h3>
                        <p>Hire based on an hourly rate and pay for hours worked. Best for ongoing projects.</p>
                        <input type="radio" name="paymentMethod" value="hourly" onChange={handlePay} checked={pay === 'hourly'}/>
                    </label>
                    <label class="option-card">
                        <div class="icon"><img src="https://img.icons8.com/ios-filled/50/000000/money-bag.png" alt="Pay fixed price"/></div>
                        <h3>Pay fixed price</h3>
                        <p>Agree on a price and release payment when the job is done. Best for one-off tasks.</p>
                        <input type="radio" name="paymentMethod" value="fixed" onChange={handlePay} checked={pay === 'fixed'}/>
                    </label>
                </div>
            </div>


            <div>
                <h5>What is your estimated budget?</h5>
                <div id='budget' style={{paddingTop:"8px",display:"flex",gap:"5%"}}>
                    <select name="" id="" style={{padding:"12px",borderRadius:"5px",maxWidth:"110px"}}>
                        <option value="USD" style={{padding:"8px"}}>USD</option>
                        <option value="USD">USD</option>
                        <option value="USD">USD</option>
                    </select>
                    
                    {
                        pay === 'hourly' ? (

                    <select onChange={handleCost} name="cost" id="" style={{padding:"12px",borderRadius:"5px",minWidth:"60%"}}>
                        <option value="1-5 USD" style={{padding:"8px"}}>Basic(1 - 5 USD per hour)</option>
                        <option value="5-20 USD">Basic(5 - 20 USD per hour)</option>
                        <option value="20-50 USD">Basic(20 - 50 USD per hour)</option>
                        <option value="50-100 USD">Basic(50 - 100 USD per hour)</option>
                        <option value="100-200 USD">Basic(100 - 200 USD per hour)</option>
                    </select>
                        ):(
                            <select onChange={handleCost} name="cost" id="" style={{padding:"12px",borderRadius:"5px",minWidth:"60%"}}>
                            <option value="100-200 USD" style={{padding:"8px"}}>Basic(100-200 USD)</option>
                            <option value="200-300 USD">Basic(200-300 USD)</option>
                            <option value="300-400 USD">Basic(300-400 USD)</option>
                            <option value="400-500 USD">Basic(400-500 USD)</option>
                            <option value="500-1000 USD">Basic(500-1000 USD)</option>
                        </select>
                        )
                    }
                </div>
            </div>
            <div>
            <button type='submit'   style={{padding:"10px 40px",color:"white",backgroundColor:"red", borderRadius:"5px",border:"none",backgroundColor:"#D3B06B",fontSize:"22px",fontWeight:"500"}}>Next</button>
            </div>
        </form>
      </div>

      
      
      
      
     
      
    </div>
  )
}

export default StepOne
