import React, { useEffect } from 'react';

const CustomDialog = ({ isOpen, onClose, children }) => {
    console.log(isOpen, "isOpen");

 
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
            document.body.style.paddingRight = `${window.innerWidth - document.body.clientWidth}px`; 
        } else {
            document.body.style.overflow = 'auto';
            document.body.style.paddingRight = ''; 
        }

        return () => {
            document.body.style.overflow = 'auto'; 
            document.body.style.paddingRight = '';  
        };
    }, [isOpen]);

    if (!isOpen) return null;

    const overlayStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 5000
    };

    const dialogBoxStyle = {
      
        padding: '20px',
        position: 'relative',
        
        width: '100%',
        overflowY: 'auto' 
    };

    return (
        <div style={overlayStyle} onClick={onClose}>
            <div style={dialogBoxStyle} onClick={(e) => e.stopPropagation()}>
                {children}
            </div>
        </div>
    );
};

export default CustomDialog;
