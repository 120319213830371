import React, { useEffect, useMemo, useState } from 'react'
import FreelancerNavbar from '../FreelancerNavbar'
import { MdShare } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import { CiStar } from "react-icons/ci";
import { BsChatLeftDotsFill } from "react-icons/bs";
import { HiMiniCurrencyDollar } from "react-icons/hi2";
import { IoStarSharp } from "react-icons/io5";
import { IoMdAddCircleOutline } from "react-icons/io";
import Cookies from 'js-cookie';
import axios from 'axios';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

import EditProfile from './EditProfile';
import CustomDialog from './CustomDiloge';
import AddExperience  from "./AddExprience";
import { Dialog } from '@material-ui/core';




const FreelancerProfile = () => {
    const [open,setOpen] = useState(false);
const [exprenceData,setExprenceData] = useState();
const cookieData = Cookies.getJSON('name');
const [isOpenExprience,setIsOpenExprience] = useState(false)


console.log(isOpenExprience,"isOpenExprience",open)
const { user} = useParams();
console.log(cookieData,"cookieData")
const memoizedCookieData = useMemo(() => cookieData, [cookieData]);
const memoizedUser = useMemo(() => user, [user]);
useEffect(() => {
    if (!memoizedCookieData || !memoizedUser) return;
  
    const data = {
      user_id: memoizedUser,
      api_key: memoizedCookieData?.user_data.id === memoizedUser ? memoizedCookieData?.user_data.api_key : "",
    };
  
    const fetchExperience = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_URL}/api/users/get_experience_by_user_id`,
          data,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        console.log(response, "response");
  
        if (response.status === 200 && response.data) {
          setExprenceData(response.data);
        } else {
          setExprenceData([]);
        }
      } catch (error) {
        console.error("Error fetching experience data:", error);
        setExprenceData([]);
      }
    };
  
    fetchExperience();
  }, []);
  
  


console.log(exprenceData,"exprenceData")

  return (
    <div>
        <FreelancerNavbar/>
        <div style={{paddingTop:"50px"}}>
            <div style={{width:'100%',height:"300px"}}>
                <img src="https://images.pexels.com/photos/3030268/pexels-photo-3030268.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" style={{width:"100%",height:"100%",objectFit:"cover"}} />
            </div>
            <div>
                <div id='profile-image' style={{position:"absolute",top:"140px",height:"260px",width:"240px",border:"5px solid white" ,borderRadius:'5px'}}>
                    <img src="https://www.f-cdn.com/assets/main/en/assets/unknown.png?image-optimizer=force&format=webply&width=336" alt="" style={{width:"100%",height:"100%",objectFit:"cover",borderRadius:"5px"}} />
                </div>
            </div>

            <div className='freelancer-name-div' style={{width:'90%',margin:"auto"}}>

                <div className='freelancer-name-div' style={{margin:"auto",paddingTop:"70px",display:'flex',alignItems:"center",gap:"10px",justifyContent:'space-between'}}>
                    <div style={{display:'flex',alignItems:"center",gap:"10px"}}>
                        <p className='freelancer-name' style={{margin:"0",color:"black",fontSize:"45px",fontWeight:"500"}}>{cookieData?.user_data?.full_name}</p>
                        <p className='freelancer-name' style={{margin:"0",color:"black",fontSize:"40px",fontWeight:"100"}}>
                            @ {cookieData && cookieData?.user_data.email && cookieData?.user_data.email.includes('@gmail.com') ? 
                         cookieData.user_data?.email.replace('@gmail.com', '') : cookieData?.user_data?.email}</p>

                    </div>

                    <div style={{display:"flex",alignItems:"center",gap:'10px'}}>
                        <MdShare className='hide' style={{fontSize:"30px", color:"black"}}/>

                        <button  className='hide' style={{border:"1px solid gray" , borderRadius:"4px",padding:"10px",fontSize:"20px",fontWeight:"500",color:"#0c0c0c"}} onClick={()=>setOpen(true)}>Edit profile</button>

                        
                        <button className='showAfter-hide' style={{border:"1px solid gray", borderRadius:"4px",padding:"6px",fontSize:"20px",display:"none",justifyContent:"center",alignItems:'center'}}><MdEdit/></button>
                        <CustomDialog isOpen={open} onClose={()=>setOpen(false)} >
                                <EditProfile setOpen={setOpen}/>
                        </CustomDialog>
                    </div>
                </div>


                <div className='freelancer-name-div' style={{margin:"10px auto",display:"flex",gap:"20px"}}>
                    <div style={{display:'flex',alignItems:'center',gap:"4px"}}>
                        <CiStar style={{fontSize:"25px"}}/>
                        <CiStar style={{fontSize:"25px"}}/>
                        <CiStar style={{fontSize:"25px"}}/>
                        <CiStar style={{fontSize:"25px"}}/>
                        <CiStar style={{fontSize:"25px"}}/>
                        0.0
                    </div>
                    
                    <div style={{display:'flex',alignItems:'center',gap:"8px"}}>
                        <BsChatLeftDotsFill style={{fontSize:"25px"}}/>
                        0
                    </div>
                    
                    <div style={{display:'flex',alignItems:'center',gap:"8px"}}>
                        <HiMiniCurrencyDollar style={{fontSize:"25px",color:'green'}}/>
                        0.0
                    </div>
                </div>

                <div className='freelancer-name-div' style={{margin:"10px auto",display:"flex",gap:"10px",alignItems:"center",paddingTop:"10px"}}>
                    <img src="https://www.f-cdn.com/assets/main/en/assets/flags/in.png" alt="" style={{width:"21px",height:"16px"}} />
                    India (9:53 AM)
                </div>

                <div className='freelancer-name-div' style={{margin:"30px auto"}}>
                    <div style={{display:"flex",justifyContent:"space-between",width:"100%"}}>
                        <h2 style={{color:"black"}}>Portfolio</h2>
                        <button  style={{border:"1px solid gray", borderRadius:"4px",padding:"6px",fontSize:"14px",display:"flex",justifyContent:"center",alignItems:'center',gap:"5px"}}><MdEdit/>Manage portfolio</button>

                    </div>

                    <div style={{padding:'10px',color:"black"}}>
                    No portfolio items have been added yet
                    </div>
                </div>

                <div className='freelancer-name-div' style={{margin:"30px auto"}}>
                    <div style={{display:"flex",justifyContent:"space-between",width:"100%",alignItems:"center"}}>
                        <h2 style={{color:"black",display:"flex",alignItems:"center",gap:"5px"}}><IoStarSharp/> 0.0 · 0 Reviews</h2>
                        {/* <button  style={{border:"1px solid gray", borderRadius:"4px",padding:"6px",fontSize:"14px",display:"flex",justifyContent:"center",alignItems:'center',gap:"5px"}}><MdEdit/>Manage portfolio</button> */}

                    </div>

                    <div style={{padding:'10px',color:"black"}}>
                    No reviews to see here!
                    </div>
                </div>

                <div className='freelancer-name-div' style={{width:"70%",}}>
                    <div style={{display:"flex",justifyContent:"space-between",width:"100%",alignItems:"center"}}>
                        <h2 style={{color:"black",display:"flex",alignItems:"center",gap:"5px"}}>Experience</h2>
                        <button  style={{border:"1px solid gray", borderRadius:"4px",padding:"6px",fontSize:"14px",display:"flex",justifyContent:"center",alignItems:'center',gap:"5px",color:"black"}} onClick={()=>setIsOpenExprience(true)}><IoMdAddCircleOutline style={{color:"black"}} />Add experience</button>

                    </div>

                    <div style={{padding:'10px',color:"black"}}>
                    No experiences have been added.
                    </div>
                </div>
                
                
                <div className='freelancer-name-div' style={{width:"70%",}}>
                    <div style={{display:"flex",justifyContent:"space-between",width:"100%",alignItems:"center"}}>
                        <h2 style={{color:"black",display:"flex",alignItems:"center",gap:"5px"}}>References</h2>
                        <button  style={{border:"1px solid gray", borderRadius:"4px",padding:"6px",fontSize:"14px",display:"flex",justifyContent:"center",alignItems:'center',gap:"5px"}}><IoMdAddCircleOutline style={{color:"black"}}/>Request reference</button>

                    </div>

                    <div style={{padding:'10px',color:"black"}}>
                    No references have been added.
                    </div>
                </div>


                <div className='freelancer-name-div' style={{width:"70%"}}>
                    <div style={{display:"flex",justifyContent:"space-between",width:"100%",alignItems:"center"}}>
                        <h2 style={{color:"black",display:"flex",alignItems:"center",gap:"5px"}}>Education</h2>
                        <button  style={{border:"1px solid gray", borderRadius:"4px",padding:"6px",fontSize:"14px",display:"flex",justifyContent:"center",alignItems:'center',gap:"5px"}}><IoMdAddCircleOutline style={{color:"black"}}/>Add education</button>

                    </div>

                    <div style={{padding:'10px',color:"black"}}>
                    No education information has been added.
                    </div>
                </div>


                <div className='freelancer-name-div' style={{width:"70%"}}>
                    <div style={{display:"flex",justifyContent:"space-between",width:"100%",alignItems:"center"}}>
                        <h2 style={{color:"black",display:"flex",alignItems:"center",gap:"5px"}}>Qualifications</h2>
                        <button  style={{border:"1px solid gray", borderRadius:"4px",padding:"6px",fontSize:"14px",display:"flex",justifyContent:"center",alignItems:'center',gap:"5px"}}><IoMdAddCircleOutline style={{color:"black"}}/>Add qualification</button>

                    </div>

                    <div style={{padding:'10px',color:"black"}}>
                    No qualifications have been added.
                    </div>
                </div>


                <div className='freelancer-name-div' style={{width:"70%"}}>
                    <div style={{display:"flex",justifyContent:"space-between",width:"100%",alignItems:"center"}}>
                        <h2 style={{color:"black",display:"flex",alignItems:"center",gap:"5px"}}>Publications</h2>
                        <button  style={{border:"1px solid gray", borderRadius:"4px",padding:"6px",fontSize:"14px",display:"flex",justifyContent:"center",alignItems:'center',gap:"5px"}}><IoMdAddCircleOutline style={{color:"black"}}/>Add publications</button>

                    </div>

                    <div style={{padding:'10px',color:"black"}}>
                    No Publications have been added.
                    </div>
                </div>


                <div className='freelancer-name-div' style={{width:"70%"}}>
                    <div style={{display:"flex",justifyContent:"space-between",width:"100%",alignItems:"center"}}>
                        <h2 style={{color:"black",display:"flex",alignItems:"center",gap:"5px"}}>Certifications</h2>
                        <button  style={{border:"1px solid gray", borderRadius:"4px",padding:"6px",fontSize:"14px",display:"flex",justifyContent:"center",alignItems:'center',gap:"5px"}}><IoMdAddCircleOutline style={{color:"black"}}/>Add certifications</button>

                    </div>

                    <div style={{padding:'10px',color:"black"}}>
                    No Certifications have been added.
                    </div>
                </div>


                <div className='freelancer-name-div' style={{width:"70%"}}>
                    <div style={{display:"flex",justifyContent:"space-between",width:"100%",alignItems:"center"}}>
                        <h2 style={{color:"black",display:"flex",alignItems:"center",gap:"5px"}}>Articles</h2>
                        <button  style={{border:"1px solid gray", borderRadius:"4px",padding:"6px",fontSize:"14px",display:"flex",justifyContent:"center",alignItems:'center',gap:"5px"}}><IoMdAddCircleOutline style={{color:"black"}}/>Add article</button>

                    </div>

                    <div style={{padding:'10px',color:"black"}}>
                    No Articles have been added.
                    </div>
                </div>
<CustomDialog isOpen={isOpenExprience} onClose={()=>setIsOpenExprience(false)}>
    <AddExperience setIsOpenExprience={setIsOpenExprience}/>
</CustomDialog>
            </div>

        </div>
    </div>
  )
}

export default FreelancerProfile
