import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup"; // For validation
import { useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import axios from "axios";

// Validation schema using Yup
const validationSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  company: Yup.string().required("Company is required"),
  country: Yup.string().required("Country is required"),
  city: Yup.string().required("City is required"),
  start_month: Yup.string().required("Start month is required"),
  start_year: Yup.string().required("Start year is required"),
  end_month: Yup.string().when("isCurrentlyWorking", {
    is: false,
    then: Yup.string().required("End month is required"),
  }),
  end_year: Yup.string().when("isCurrentlyWorking", {
    is: false,
    then: Yup.string().required("End year is required"),
  }),
  work_description: Yup.string().required("Work description is required"),
});

const AddExperience = ({ setIsOpenExprience }) => {
  const [isCurrentlyWorking, setIsCurrentlyWorking] = useState(false);
  const cookieData = Cookies.getJSON('name');
  const { user } = useParams();

  const initialValues = {
    title: "",
    company: "",
    country: "",
    city: "",
    start_month: "",
    start_year: "",
    end_month: "",
    end_year: "",
    work_description: "",
  };

  const onSubmit = async (values) => {
    console.log("Form values before submission:", values);
    const data = {
      ...values,
      is_currently: isCurrentlyWorking,
      user_id: user,
      api_key: cookieData?.user_data.id === user ? cookieData?.user_data.api_key : "",
    };
    try {
      const res = await axios.post(`${process.env.REACT_APP_URL}/api/users/add_experience`, data);
      console.log(res);
      if (res.data.code) {
        setIsOpenExprience(false);
      } else {
        console.error("API did not return a success code:", res.data);
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };

  const toggleCurrentlyWorking = () => {
    setIsCurrentlyWorking(!isCurrentlyWorking);
  };



  const containerStyle = {
    padding: "20px",
    width: "900px", 
    minWidth: "900px", 
    margin: "20px auto", 
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    backgroundColor: "#fff",
    height: "90vh", 
    boxSizing: "border-box", 
    overflowX: "hidden", 
    overflowY: "auto", 
  };

  const formStyle = {
    display: "grid",
    gridTemplateColumns: "1fr 1fr", 
    gap: "20px",
    width: "100%",
  };

  const responsiveContainerStyle = {
    ...containerStyle,
    maxWidth: "100vw", 
    margin: "0 auto",
    overflowX: "hidden", 
  };

  const responsiveFormStyle = {
    ...formStyle,
    gridTemplateColumns: "1fr",
  };

  const inputGroupStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  };

  const labelStyle = {
    fontWeight: "bold",
    fontSize: "14px",
    color: "#333",
  };

  const inputStyle = {
    padding: "12px",
    border: "1px solid #ccc",
    fontWeight:"400",
    borderRadius: "4px",
    outline: "none",
    width: "100%",
  };

  const selectStyle = {
    ...inputStyle,
    appearance: "none",
  };

  const checkboxStyle = {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  };

  const errorStyle = {
    color: "red",
    fontSize: "12px",
  };

  const buttonStyle = {
    padding: "12px 35px",
    backgroundColor: "#DAB874",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  };
  const isSmallScreen = window.innerWidth < 768;

  const currentYear = new Date().getFullYear(); 
  const years = []; 
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

 
  for (let year = 1900; year <= currentYear; year++) {
    years.push(year);
  }
  return (
    <div style={isSmallScreen ? responsiveContainerStyle : containerStyle}>
      <h2>Add Experience</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
        <Form  style={isSmallScreen ? responsiveFormStyle : formStyle}>
          <div style={inputGroupStyle}>
            <label htmlFor="title" style={labelStyle}>Title</label>
            <Field
              type="text"
              id="title"
              name="title"
              placeholder="Enter your position or title"
              style={inputStyle}
            />
            <ErrorMessage name="title" component="div" style={errorStyle} />
          </div>

          <div style={inputGroupStyle}>
            <label htmlFor="company" style={labelStyle}>Company</label>
            <Field
              type="text"
              id="company"
              name="company"
              placeholder="Enter company name"
              style={inputStyle}
            />
            <ErrorMessage name="company" component="div" style={errorStyle} />
          </div>

          <div style={inputGroupStyle}>
            <label htmlFor="country" style={labelStyle}>Country</label>
            <Field as="select" id="country" name="country" style={selectStyle}>
              <option value="">Select country</option>
              <option value="USA">USA</option>
              <option value="India">India</option>
            </Field>
            <ErrorMessage name="country" component="div" style={errorStyle} />
          </div>

          <div style={inputGroupStyle}>
            <label htmlFor="start_year" style={labelStyle}>Start Year</label>
            <Field as="select" id="start_year" name="start_year" style={selectStyle}>
              <option value="">Select year</option>
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </Field>
            <ErrorMessage name="start_year" component="div" style={errorStyle} />
          </div>

          <div style={inputGroupStyle}>
            <label htmlFor="start_month" style={labelStyle}>Start Month</label>
            <Field as="select" id="start_month" name="start_month" style={selectStyle}>
              <option value="">Select month</option>
              {months.map((month, index) => (
                <option key={index} value={month}>
                  {month}
                </option>
              ))}
            </Field>
            <ErrorMessage name="start_month" component="div" style={errorStyle} />
          </div>

          <div style={checkboxStyle}>
            <Field
              type="checkbox"
              name="isCurrentlyWorking"
              checked={isCurrentlyWorking}
              onChange={toggleCurrentlyWorking}
            />
            <label style={labelStyle}>I'm currently working here</label>
          </div>

          {!isCurrentlyWorking && (
            <>
              <div style={inputGroupStyle}>
                <label htmlFor="end_month" style={labelStyle}>End Month</label>
                <Field as="select" id="end_month" name="end_month" style={selectStyle}>
                  <option value="">Select month</option>
                  {months.map((month, index) => (
                    <option key={index} value={month}>
                      {month}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="end_month" component="div" style={errorStyle} />
              </div>

              <div style={inputGroupStyle}>
                <label htmlFor="end_year" style={labelStyle}>End Year</label>
                <Field as="select" id="end_year" name="end_year" style={selectStyle}>
                  <option value="">Select year</option>
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="end_year" component="div" style={errorStyle} />
              </div>
            </>
          )}

          <div style={inputGroupStyle}>
            <label htmlFor="work_description" style={labelStyle}>Work Description</label>
            <Field
              as="textarea"
              id="work_description"
              name="work_description"
              placeholder="Describe your work experience"
              style={inputStyle}
            />
            <ErrorMessage name="work_description" component="div" style={errorStyle} />
          </div>

          <button type="submit"    style={buttonStyle} disabled={isSubmitting}>Save</button>
        </Form>)}
      </Formik>
    </div>
  );
};

export default AddExperience;
