import { Button, Grid, TextField, Typography } from '@material-ui/core'
import React from 'react'
import { IoClose } from 'react-icons/io5'

const EditProfile = ({setOpen}) => {
  return (

    <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>

    <div style={{height:'auto',width:'50%',padding:"20px",background:'white',borderRadius:'8px'}}>

      <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
        <div style={{fontSize:'18px',fontWeight:'500'}}>Edit Profile</div>
         <IoClose onClick={()=>setOpen(false)} style={{cursor:'pointer'}}/>
      </div>
        <div>You will have the ability to choose which profile to display in your bids</div>

        <Grid container spacing={2} style={{marginTop:'5px'}}>
              <Grid item sm={12} lg={6} xs={12}>
              <Typography style={{color:'black'}}>Hourly Rate</Typography>
                <TextField variant='outlined'   fullWidth />
              </Grid>
              <Grid item sm={12} lg={6} xs={12}>
              <Typography style={{color:'black'}}>Professional Headline</Typography>
                <TextField variant='outlined'    fullWidth />
              </Grid>
            </Grid>

            <div style={{marginTop:'10px'}}>
            <Typography style={{color:'black'}}>Top Skills</Typography>
            <TextField variant='outlined'   fullWidth multiline rows={6}/>
            </div>

            <div style={{marginTop:'0px'}}>
            <Typography style={{color:'black'}}>Summary</Typography>
            <TextField variant='outlined'   fullWidth multiline rows={8}/>
            </div>
<div style={{display:'flex',gap:'10px',marginTop:'10px'}}>
            <Button onClick={()=>setOpen(false)} variant='outlined' size='small'>Back</Button>
            <Button variant='containe' size='small' style={{background:'blue',color:'white'}}>save</Button>
</div>
    </div>
    </div>
  )
}

export default EditProfile
