import React, { Component } from 'react';

import axios from 'axios';
import toastr from 'reactjs-toastr';
import Cookies from 'js-cookie';
import { IoIosArrowRoundForward } from "react-icons/io";
import $ from 'jquery'
import Navbar from '../layouts/Navbar';
import Footer from '../layouts/Footer';
import InfoForm from '../contents/InfoForm';
const headers = {
    'Content-Type': 'text/plain'
};
const TITLE = 'Victus-Club-Login'
const initialState = {
    email: '',
    password: '',
    emailError: '',
    passwordError: '',
    loggedIn: '',
    code: ''
}

export default class Home extends Component {

    constructor(props) {
        super(props)

        this.state = initialState

        this.onChange = this.onChange.bind(this);
        this.submitForm = this.submitForm.bind(this);

        var pageURL = window.location.hash;
        let res = pageURL.split("/");

        this.investFind = res[5]

        var lastURLSegment = pageURL.substr(pageURL.lastIndexOf('=') + 1);
        var checkLastURL = pageURL.substr(pageURL.lastIndexOf('=') - 9);
        let checkLastURL1 = checkLastURL.split("=")
        this.checkLastURL1 = checkLastURL1[0]


        this.lastUrl = lastURLSegment
        if (Cookies.getJSON('name')) {

            let data = Cookies.getJSON('name');
            this.loginData = data.user_data
        }

    }
    // validation check 
    validate = () => {
        let emailError = '';
        let passwordError = '';

        if (!this.state.email.includes("@")) {

            emailError = "User name is required"
        }

        if (this.state.password.length <= 0) {
            passwordError = "Password is required"
        }
        if (emailError || passwordError) {
            this.setState({ emailError, passwordError });
            return false
        }

        return true
    }

    componentDidMount() {
        // $('#topcontrol').hide()
        setTimeout(() => {

            $('#topcontrol').css('display', 'none');
        }, 1000);

    }

    onChange(e) {

        this.setState({
            [e.target.name]: e.target.value
        })
    }

    //submit the data
    async submitForm(e) {
        e.preventDefault()
        const isValid = this.validate();
        if (isValid) {

            this.setState(initialState)
            this.setState({
                loading: true
            })
            this.state.code = this.lastUrl
            delete this.state.emailError
            delete this.state.passwordError
            delete this.state.loggedIn



            const data = this.state


            await axios.post(`${process.env.REACT_APP_URL}/api/users/login`, data, { headers })
                .then(response => {

                    if (response.data.code === true) {
                        toastr.success('Sucess', { displayDuration: 3000 })
                        Cookies.set('name', response.data);

                        this.setState({
                            loading: false,
                            loggedIn: true,
                            message: response.data
                        })
                        if (this.state.message.user_data.is_enable_google_auth_code === '0') {
                            
                            window.location.hash = '/dashboard'
                        }
                        else if (this.state.message.user_data.is_enable_google_auth_code === '1') {
                            // window.location.reload(true);
                            window.location.hash = '/twofa'
                        }

                    }
                    else if (response.data.code === false) {
                        toastr.error(response.data.message, { displayDuration: 3000 })
                        this.setState({

                            loggedIn: false,

                        });
                    }

                })

                .catch(err => {
                    this.setState({
                        loading: false
                    })
                })

        }
        else {
        }


    }

    forgot() {
        setTimeout(() => {

            window.location.reload(true)
        }, 100);
    }



    render() {

        return (
            <div>

                <>
                   

                    <Navbar/>
                    {/* <Headerhome /> */}

                    <>
                     <div className='homeHeader'>
                        <div className='container101' >
                        {/* <div>WORLD CLASS SOCIAL PLATFORM PRODUCTS</div> */}
                           <div style={{fontSize:'40px',fontWeight:'700',color:'black'}}>We are many, they are few!</div>
                           <div style={{fontSize:'40px',fontWeight:'700',color:'black'}}>Collective power of Victus. </div>
                           {/* <div>victus a social platform uniting people and developing a strong mutual foundation to transform society by ...</div> */}
                           {/* <button className='homeButton' style={{marginTop:'20px',display:'flex',alignItems:'center',gap:'10px'}}>Get started now <IoIosArrowRoundForward style={{fontSize:'25px'}}/> </button> */}
                        </div>
                        {/* <div className='container102' >
                          <img className='headerImage' src='https://img.freepik.com/free-photo/creative-people-working-office_23-2147656715.jpg?w=996'/>
                        </div> */}
                     </div>

                      


                      <div className='container07'>
                        <div className='contentBox' style={{flexDirection:'column'}}>
                           <div style={{fontSize:'24px',fontWeight:'500',color:'black'}}>About Victus Token</div>
                           <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                             <div className='content007'>Victus Token pioneers a decentralized ecosystem, leveraging Internet 3.0 technology and blockchain for resilience and transparency in digital transactions.</div>

                             {/* <button className='regButton' style={{display:'flex',alignItems:'center',gap:'10px'}}>Know more <IoIosArrowRoundForward style={{fontSize:'25px'}}/> </button> */}
                           </div>

                           <div className='contentBox008 boxgap' style={{marginTop:'30px',flexWrap:'wrap'}}>


                          
                            <div className='button09 ' style={{height:'110px',border:'1px solid #EFCC50',borderRadius:'5px',display:'flex',alignItems:'center',gap:'10px',position:'relative'}}>
                                <img src='/messege.png' style={{margin:'15px'}}/>
                                <div >
                                <div style={{fontSize:'18px',fontWeight:'500',color:'black'}}>Free Speech</div>
                                  <div style={{fontSize:'14px',color:'rgba(0,0,0,0.5)'}}>Post your views, create surveys, and share your photos and memories.</div>
                                </div>
                                 </div>
                              


                                 <div className='button09' style={{height:'110px',border:'1px solid #1E64E7',borderRadius:'5px',display:'flex',alignItems:'center',gap:'10px'}}>
                                <img src='/decentralisation.png' style={{margin:'15px'}}/>
                                <div>
                                <div style={{fontSize:'18px',fontWeight:'500',color:'black'}}>V Tube</div>
                                <div style={{fontSize:'14px',color:'rgba(0,0,0,0.5)'}}>Create your channel. Post your videos, get paid, and build your empire!

                                </div>
                                </div>
                                 </div>


                                 <div className='button09' style={{height:'110px',borderRadius:'5px',display:'flex',alignItems:'center',gap:'10px',background:'#1E64E7'}}>
                                <img src='/blockchain.png' style={{margin:'15px'}}/>
                                <div>
                                <div style={{fontSize:'18px',fontWeight:'500',color:'black'}}>Freelance</div>
                                <div style={{fontSize:'14px',color:'rgba(0,0,0,0.5)'}}>Post your skills and be hired today! From digital nomads to tradespeople, advertise yourself or company today!


                                </div>
                                </div>
                                 </div>


                                 <div className='button09' style={{height:'110px',border:'1px solid #EEA886',borderRadius:'5px',display:'flex',alignItems:'center',gap:'10px'}}>
                                <img src='/crowdfund.png' style={{margin:'15px'}}/>
                                <div>
                                <div style={{fontSize:'18px',fontWeight:'500',color:'black'}}>Followers</div>
                                <div style={{fontSize:'14px',color:'rgba(0,0,0,0.5)'}}>Unlimited friends and followers with the ability to be paid for your content straight to your wallet!

</div>
                                </div>
                                 </div>

                                 {/* <div className='button09' style={{height:'80px',border:'1px solid #EEA886',borderRadius:'5px',display:'flex',alignItems:'center',gap:'10px'}}>
                                <img src='/crowdfund.png' style={{margin:'15px'}}/>
                                <div style={{fontSize:'18px',fontWeight:'500',color:'black'}}>V Tok</div>
                                 </div> */}
                           </div>
                        </div>
                      </div>


                      {/* <div className='container05' style={{background:'#1b3160'}}>
                      <div className='contentBox006'>
                         <div className='lowerContent'>
                            <div style={{fontSize:'25px',fontWeight:'600',color:'white'}}>Our Service We Provide</div>
                             <div style={{color:'white'}}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates mollitia tenetur ab assumenda magnam commodi nesciunt facilis quidem. Quo, amet!</div>
                             <button className='regButton' style={{marginTop:'20px',display:'flex',alignItems:'center',gap:'10px'}}>More Details<IoIosArrowRoundForward style={{fontSize:'25px'}}/> </button>
                         </div>

                         <div className='content99 ' style={{justifyContent:'space-between'}}>
                             <div className='content88 marginBox' style={{background:'white',borderRadius:'10px',display:'flex'}}>
                                <div style={{width:'50%',display:'flex',alignItems:'center',height:'100%',background:'#f8f9ff',padding:'10px',borderRadius:'10px'}}>
                                   <img src='/frame2.png'/>
                                </div>
                                 <div style={{width:'50%',padding:'10px',display:'flex',alignItems:'center'}}>
                                    <div style={{fontSize:'18px',fontWeight:'500'}}>Seamless trading platform</div>
                                 </div>
                             </div>

                             <div className='content88 marginBox' style={{background:'white',borderRadius:'10px',display:'flex'}}>
                                <div style={{width:'50%',display:'flex',alignItems:'center',height:'100%',background:'#3473e9',padding:'10px',borderBottomLeftRadius:'10px',borderTopLeftRadius:'10px'}}>
                                   <img src='/frame4.png'/>
                                </div>
                                 <div style={{width:'50%',padding:'10px',display:'flex',alignItems:'center',background:'#1e64e7',borderTopRightRadius:'10px',borderBottomRightRadius:'10px'}}>
                                    <div style={{fontSize:'18px',fontWeight:'500'}}>Seamless trading platform</div>
                                 </div>
                             </div>


                             <div className='content88' style={{background:'white',borderRadius:'10px',display:'flex',marginTop:'30px'}}>
                                <div style={{width:'50%',display:'flex',alignItems:'center',height:'100%',background:'#f8f9ff',padding:'10px',borderRadius:'10px'}}>
                                   <img src='/frame1.png'/>
                                </div>
                                 <div style={{width:'50%',padding:'10px',display:'flex',alignItems:'center'}}>
                                    <div style={{fontSize:'18px',fontWeight:'500'}}>Seamless trading platform</div>
                                 </div>
                             </div>

                             <div className='content88 marginBot' style={{background:'white',borderRadius:'10px',display:'flex',marginTop:'30px'}}>
                                <div style={{width:'50%',display:'flex',alignItems:'center',height:'100%',background:'#f8f9ff',padding:'10px',borderRadius:'10px'}}>
                                   <img src='/frame3.png'/>
                                </div>
                                 <div style={{width:'50%',padding:'10px',display:'flex',alignItems:'center'}}>
                                    <div style={{fontSize:'18px',fontWeight:'500'}}>Seamless trading platform</div>
                                 </div>
                             </div>
                         </div>
                      </div>
                      </div> */}
                      


                      {/* <div className='container01'>
                        <div className='contentBox'>
                        <div className='contentBox02' >
                        
                           <div style={{fontSize:'30px',fontWeight:'500',color:'black'}}>Decentralized Transparency for Free Speech</div>
                           <div style={{fontSize:'14px'}}>Our code and algorithms are free and open source for maximum transparency and accountability. Running on decentralised new Internet 3.0 technology registered on the Blockchain.Our content policy is based on the Manila Principles and governed by a community jury in order to minimize bias and censorship suppporting free speech.</div>
                           <div style={{fontSize:'14px'}}>Our algorithms are freely accessible, fostering transparency and accountability in our operations.</div>
                           <div style={{fontSize:'14px'}}>Decentralized Internet 3.0 Blockchain Power</div>
                           <button className='homeButton' style={{marginTop:'20px',display:'flex',alignItems:'center',gap:'10px'}}>More Details<IoIosArrowRoundForward style={{fontSize:'25px'}}/> </button>
                        </div>
                          <div className='imageContent'>
                            <img src='homepage-2.png' style={{height:'100%',borderRadius:'10px'}}/>
                          </div>
                        </div>

                      </div> */}


                      {/* <div className='container01' >
                        <div className='contentBox'>

                        <div className='imageContent'>
                            <img src='homepage-3.jpg' style={{height:'100%',borderRadius:'10px'}}/>
                          </div>
                        <div className='contentBox00'>
                        
                           <div style={{fontSize:'30px',fontWeight:'500',color:'black'}}>Decentralized Transparency for Free Speech</div>
                           <div style={{fontSize:'14px'}}>Our code and algorithms are free and open source for maximum transparency and accountability. Running on decentralised new Internet 3.0 technology registered on the Blockchain.Our content policy is based on the Manila Principles and governed by a community jury in order to minimize bias and censorship suppporting free speech.</div>
                           <div style={{fontSize:'14px'}}>Our algorithms are freely accessible, fostering transparency and accountability in our operations.</div>
                           <div style={{fontSize:'14px'}}>Decentralized Internet 3.0 Blockchain Power</div>
                           <button className='homeButton' style={{marginTop:'20px',display:'flex',alignItems:'center',gap:'10px'}}>More Details<IoIosArrowRoundForward style={{fontSize:'25px'}}/> </button>
                        </div>
                        
                        </div>

                      </div> */}
                        
                       

                      {/* <div className='container003'> */}
                      {/* <div className='content003'> */}
                        {/* <div className='content004' > */}
                            {/* <div>
                            <div style={{fontSize:'30px'}}>Give your requirements,,! We are ready to work</div>
                            <div>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ut nemo, est vel aliquid nesciunt nihil, cumque corporis, obcaecati incidunt sit consequuntur suscipit voluptas doloribus reiciendis autem eos consectetur animi distinctio.</div>
                            </div> */}
                            {/* <div style={{marginTop:'30px',display:'flex',justifyContent:'space-between'}}>
                                <div style={{height:'130px',width:'150px',background:'white',borderRadius:'10px',padding:'10px'}}>
                                    <img src='/call.png'/>
                                    <div style={{fontSize:'16px'}}>Contact with Mail</div>
                                    <div>info@victus.com</div>
                                </div>

                               
                            </div> */}
                        {/* </div> */}

                        {/* <InfoForm/> */}
                      {/* </div> */}
                      {/* </div> */}



                     

                        

                       
                    </>
                   
                  <Footer/>
                </>

            </div>

        )
    }
}



//   <script type="text/javascript" src="assets/js/particles.js"></script>
