import { Button, Card, CardContent, Divider, Grid, TextField, Typography } from '@material-ui/core'
import React from 'react'

const Membership = () => {
  return (
    <div>
      <Card>
        <CardContent>
        <Typography variant="h5" gutterBottom style={{fontWeight:'600'}}>
              Memberships
            </Typography>

            <Divider style={{margin:'20px 0px'}} />

            <Typography variant="h6" gutterBottom style={{fontWeight:'600'}}>
              Current Plans
            </Typography>

            <Grid container spacing={2} style={{marginTop:'10px'}}>
              <Grid item xs={6}>
              <Typography style={{color:'black',fontWeight:'500'}}>Free</Typography>
               <Typography>$ 0 USD per month</Typography>
               <Button size='small' variant='outlined' style={{marginTop:'10px'}}>Manage</Button>
              </Grid>
              <Grid item xs={6}>
              <Typography style={{color:'black',fontWeight:'500'}}>Billing Information</Typography>
               <Typography style={{color:'#58AFF5'}}>View Transaction History | Manage Payment Method</Typography>
              </Grid>
            </Grid>

            <TextField variant='outlined' value='You are eligible for a free Plus Membership. To claim your free 1 month trial, click here.' fullWidth/>
        </CardContent>
      </Card>
    </div>
  )
}

export default Membership
