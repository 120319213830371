import { Avatar, Button, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { MdEdit } from "react-icons/md";
import Axios from "axios";
import Cookies from "js-cookie";

const EditChannel = ({ setOpen, channelId, data,setRefresh }) => {
  const [cookieData, setCookieData] = useState({});
  const [channel, setChannel] = useState(data?.name);
  const [selectedImage, setSelectedImage] = useState(data?.file || "");
  const [filetype,setFiletype] = useState()

  useEffect(() => {
    let cookie = Cookies.getJSON("name");
    setCookieData(cookie?.user_data);
  }, []);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageURL = URL.createObjectURL(file);
      setSelectedImage(imageURL);
      setFiletype(file);
    }
  };

  const updateChannelData = async () => {
    if(Object.keys(cookieData).length > 0 ){
      let formdata = new FormData()
      formdata.append('api_key',cookieData?.api_key)
      formdata.append('user_id',cookieData?.id)
      formdata.append('channel_name',channel)
      formdata.append('file',filetype)
      formdata.append("channel_id",channelId)
      formdata.append('file_type',selectedImage)

    const response = await Axios.post(
      `${process.env.REACT_APP_URL}/api/users/update_channel`,
      formdata,
      {
        headers: {
          "Content-Type": "text/plain",
          Accept: "application/json, text/plain, */*",
        },
      }
    );

    if (response.data.code === true) {
      setOpen(false);
      setRefresh(true)
      return true;
    }
  }
  };

  return (
    <div className="addPopup">
      {/* Close Button */}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <IoClose onClick={() => setOpen(false)} style={{ fontSize: "25px", cursor: "pointer" }} />
      </div>

      {/* Avatar Upload with Edit Icon */}
      <div style={{ display: "flex", justifyContent: "center", position: "relative", marginBottom: "15px" }}>
        <label htmlFor="avatar-upload" style={{ cursor: "pointer", position: "relative" }}>
          <Avatar
            src={selectedImage}
            style={{ height: "100px", width: "100px" }}
          />
          {/* Edit Icon */}
          <div
            style={{
              position: "absolute",
              bottom: "5px",
              right: "5px",
              background: "#fff",
              borderRadius: "50%",
              padding: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0px 0px 5px rgba(0,0,0,0.2)"
            }}
          >
            <MdEdit style={{ fontSize: "18px", color: "#555" }} />
          </div>
        </label>
        <input
          id="avatar-upload"
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: "none" }}
        />
      </div>

      {/* Title */}
      <Typography variant="h6" style={{ fontWeight: "bold" }}>
        Edit Channel
      </Typography>

      {/* Channel Name Input */}
      <TextField
        value={channel}
        onChange={(e) => setChannel(e.target.value)}
        fullWidth
        variant="outlined"
        placeholder="Channel name"
        style={{ marginTop: "20px" }}
      />

      {/* Buttons */}
      <div style={{ marginTop: "20px", display: "flex", justifyContent: "center", gap: "10px" }}>
        <Button onClick={() => setOpen(false)} variant="outlined">
          Cancel
        </Button>
        <Button onClick={updateChannelData} variant="contained" color="primary">
          Save
        </Button>
      </div>
    </div>
  );
};

export default EditChannel;
